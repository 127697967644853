/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";

const Toggle = forwardRef(
  (
    { className, defaultChecked = false, label, dataId, onChange, disabled },
    ref
  ) => {
    const [checked, setChecked] = useState(defaultChecked);
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
      setChecked(defaultChecked);
    }, [defaultChecked]);

    useEffect(() => {
      const handleMouseUp = () => {
        if (dragging) {
          setDragging(false);
          handleToggle();
        }
      };

      document.addEventListener("mouseup", handleMouseUp);

      return () => {
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }, [dragging]);

    const handleToggle = () => {
      const newChecked = !checked;
      setChecked(newChecked);
      if (onChange) {
        onChange(newChecked);
      }
    };

    const handleMouseDown = () => {
      !disabled && setDragging(true);
    };

    const handleMouseMove = (e) => {
      disabled && setDragging(false);
      if (dragging) {
        const { clientX, target } = e;
        const { left, width } = target.getBoundingClientRect();
        const newPos = Math.min(Math.max(0, clientX - left), width);
        const newChecked = newPos >= width / 2;
        setChecked(newChecked);
      }
    };

    return (
      <ToggleWrapper data-id={dataId} className={className}>
        <ToggleInput disabled={disabled} type='checkbox' checked={checked} onChange={handleToggle} />
        <Slider
          data-disabled={disabled}
          className='slider'
          checked={checked}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          ref={ref}
        />
        {label && <ToggleLabel>{label}</ToggleLabel>}
      </ToggleWrapper>
    );
  }
);

Toggle.displayName = "Toggle";

export default Toggle;

const ToggleWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
`;

const Slider = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 3rem;
  height: 1.5rem;
  background-color: ${({ checked }) => (checked ? "var(--primaryColor, #3D4465)" : "#ccc")};
  border-radius: 1.25rem;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 1.125rem;
    width: 1.125rem;
    left: ${({ checked }) => (checked ? "calc(100% - 1.375rem)" : "0.25rem")};
    bottom: 50%;
    transform: translate(0, 50%);
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }

  &[data-disabled="true"] {
    background-color: var(--neutralColor3, gray);
    cursor: not-allowed;

    &:before {
      background-color: var(--neutralColor6, gray);
    }
  }
`;

const ToggleLabel = styled.span`
font-size: 1rem;
  color: var(--primaryColor, #007bff);
`;