import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import cx from "classnames";
import PropTypes from "prop-types";

import nextPage from "./icons/pagination-next-arrow.svg";
import previousPage from "./icons/pagination-previous-arrow.svg";
import styles from "./Pagination.module.scss";
import CustomSelect from "../CustomSelect/CustomSelect";


const Pagination = ({
  totalNumberOfPages,
  onPageClick,
  onSizeChange,
  showSizePicker,
  resultPerPage,
  resetPageIndex,
  activePage
}) => {
  const [pageCount, setPageCount] = useState(totalNumberOfPages);
  const [perPage, setPerPage] = useState(resultPerPage);
  const [currentPage, setCurrentPage] = useState(activePage);

  useEffect(() => {
    setPageCount(totalNumberOfPages);
    setPerPage(resultPerPage);
  }, [totalNumberOfPages, resultPerPage]);

  useEffect(() => {
    resetPageIndex && setCurrentPage(0);
  }, [resetPageIndex]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    onPageClick(selectedPage + 1);
    setCurrentPage(selectedPage + 1);
  };

  const handlePerPageChange = (data) => {
    setPerPage(data.value);
    onSizeChange?.(data.value);
    setCurrentPage(0);
  };

  const pageSizeData = [
    { value: 5, label: "5 rows" },
    { value: 10, label: "10 rows" },
    { value: 50, label: "50 rows" },
    { value: 100, label: "100 rows" }
  ];

  return (
    <div className={cx(styles.paginationContainer)}>
      {showSizePicker && (
        <label className={cx(styles.sizePickerLabel)}>
          <span>Showing</span>
          <CustomSelect
            options={pageSizeData}
            defaultValue={pageSizeData.find((option) => option.value === perPage)?.value}
            onChange={(data) => handlePerPageChange(data)}
            placeholder='Select'
            $marginbottom='0'
            className='paginationCustomSelect'
            value={pageSizeData.find((option) => option.value === perPage)}
          />
        </label>
      )}

      <div className={cx(styles.mainWrapper)}>
        <ReactPaginate
          previousLabel={<img src={previousPage} alt='previous page' />}
          nextLabel={<img src={nextPage} alt='previous page' />}
          pageCount={pageCount}
          marginPagesDisplayed={3}
          pageRangeDisplayed={0}
          forcePage={currentPage ? currentPage - 1 : 0}
          onPageChange={handlePageClick}
          containerClassName={styles.pagination}
          disabledClassName={styles.disabled}
          activeClassName={styles.active}
        />
      </div>
    </div>
  );
};

Pagination.propTypes = {
  totalNumberOfPages: PropTypes.number.isRequired,
  onPageClick: PropTypes.func.isRequired,
  onSizeChange: PropTypes.func,
  showSizePicker: PropTypes.bool,
  resultPerPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalNumberOfItems: PropTypes.number,
  resetPageIndex: PropTypes.bool,
  activePage: PropTypes.number
};

export default Pagination;
