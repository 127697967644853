import React from 'react';
// import { useGetChartDataQuery } from '../../admin/redux/Dashboard/dashboardApi';
import CategoryGrid from '../components/CategoryTable';
import BrandSetup from '../components/BrandSetup';
import DeliveryOptions from '../components/DeliveryOptions';
import MerchantSettings from '../components/MerchantSettings';
import HomepageImage from '../components/HomePageImage';

const FrontSetup = () => {
     return (
        <div className="content-wrapper">
        {/* <!-- row --> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-6">
                        <div id="user-activity" className="card">
                            <BrandSetup />
                        </div>
                    </div>   
                    <div className="col-xl-6">
                        <div className="row card">
                           <CategoryGrid />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">                                           
                                      <HomepageImage />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="card">
                                   <MerchantSettings />
                                </div>
                            </div>
                        </div>   
                    </div>   
                    <div className="col-xl-6">
                        <div className="row">
                                <div className="card">
                                    <DeliveryOptions />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FrontSetup;