import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import logo from '../images/tabulbyzenithBlack.png'

const PrintReceiptModal = ({ show, onHide, receipt }) => {
  const receiptRef = React.useRef(null);

  const generateTransactionReceiptImage = () => {
    if (receiptRef.current) {
      html2canvas(receiptRef.current).then((canvas) => {
        const link = document.createElement('a');
        link.download = `receipt-${receipt.order_id}.png`;
        link.href = canvas.toDataURL();
        link.click();
      });
    }
  };

  if (!receipt) return null;

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Body>
        <div ref={receiptRef} id="transaction-receipt">
          <div className="centered-image mt-2">
            <img src={logo} alt="" style={{ height: '60px', marginTop: '20px' }} />
          </div>
          <h3 className="title is-3 mb-2 mt-4" style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', textAlign: 'center' }}>Transaction Receipt</h3>
          <div style={{ backgroundColor: 'whitesmoke', margin: 'auto' }}> 
            <p style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', fontWeight: 'bold', fontSize: 'large', padding: '10px' }}> Payment Details </p>
          </div>
          <p style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', fontWeight: 'bold', fontSize: 'large', padding: '10px' }}> <strong> Transaction Date: </strong> {new Date(receipt.created_at).toLocaleString()}</p>
          <hr style={{ backgroundColor: 'whitesmoke', margin: '5px', padding: '0px' }} />
          <p style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', fontWeight: 'bold', fontSize: 'large', padding: '10px' }}> <strong> Transaction Amount: </strong> {receipt.amount}</p>
          <hr style={{ backgroundColor: 'whitesmoke', margin: '5px', padding: '0px' }} />
          <p style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', fontWeight: 'bold', fontSize: 'large', padding: '10px' }}> <strong> Payment Type: </strong> {receipt.payment_type}</p>
          <hr style={{ backgroundColor: 'whitesmoke', margin: '5px', padding: '0px' }} />
          {receipt.payment_type === 'bank_transfer' && (
            <>
              <p style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', fontWeight: 'bold', fontSize: 'large', padding: '10px' }}> <strong> Payer's Name: </strong> {receipt.payers_name}</p>
              <hr style={{ backgroundColor: 'whitesmoke', margin: '5px', padding: '0px' }} />
            </>
          )}
          <p style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', fontWeight: 'bold', fontSize: 'large', padding: '10px' }}> <strong> Order Type: </strong> {receipt.is_online === '1' ? 'Online Order' : 'Dine In'}</p>
          <hr style={{ backgroundColor: 'whitesmoke', margin: '5px', padding: '0px' }} />
          <p style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', fontWeight: 'bold', fontSize: 'large', padding: '10px' }}> <strong> Order ID: </strong> {receipt.order_id}</p>
          <hr style={{ backgroundColor: 'whitesmoke', margin: '5px', padding: '0px' }} />
          <p style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', fontWeight: 'bold', fontSize: 'large', padding: '10px' }}> <strong> Transaction ID: </strong> {receipt.transaction_id}</p>
          
          <footer style={{ height: '50px', width: '100%', backgroundColor: 'red', marginTop: '13px' }}>
            <h6 className="subtitle is-6" style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', textAlign: 'center', paddingTop: '15px', color: 'white' }}> &copy;2024 Tabul-By-Zenith  All Rights Reserved </h6>
          </footer>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={generateTransactionReceiptImage}>Download</Button>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintReceiptModal;