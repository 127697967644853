import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  full_name : "",
  business_name : "",
  email : "",
  state : "",
  street : "",
  phone_number : "",
  password : "",
  password_confirmation : "",
  user_type_id: 1,
  country : "",
  currency : ""
};

const regSlice = createSlice({
  name: 'reg',
  initialState,
  reducers: {
    userRegForm: (state, action) => {
      state.full_name = action.payload.full_name;
      state.business_name = action.payload.business_name;
      state.email = action.payload.email;
      state.state = action.payload.state;
      state.street = action.payload.street;
      state.phone_number = action.payload.phone_number;
      state.password = action.payload.password;
      state.password_confirmation = action.payload.password_confirmation;
      state.country = action.payload.country;
      state.currency = action.payload.currency;
    },
  },
});

export const { userRegForm } = regSlice.actions;

export default regSlice.reducer;
