import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';  // Import the library
import { Modal, Button } from 'react-bootstrap';

function QRCreator() {
  const [isLoading, setIsLoading] = useState(false);
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);
  const [qrData, setQRData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const qrRef = React.createRef();

  // Get user_id and isProvidus from localStorage
  const user_id = localStorage.getItem("user_id");
  const isProvidus = localStorage.getItem("isProvidus");

  // Set QR color based on isProvidus
  const qrColor = isProvidus === 'true' ? "#FFB200" : "#5C3C4EFF";

  // Generate URL for QR code
  const url = window.location.href.split('pages')[0] + '/#!/restaurant/' + user_id;

  const handleGenerateQRCode = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setQRData(url);  // Set the data for the QR code after loading
      setIsLoading(false);
      setIsReadyToDownload(true);
      setShowModal(true); // Open modal after QR code is ready
    }, 1700);
  }

  const handleDownload = () => {
    const canvas = qrRef.current.querySelector('canvas');
    const link = document.createElement('a');
    link.download = 'qr-code.png';
    link.href = canvas.toDataURL();
    link.click();
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <div className="section is-centered is-justify-content-center has-text-centered">
      {!isLoading && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div ref={qrRef}>
              <QRCodeCanvas
                value={qrData}
                size={400}
                bgColor="#e9ebee"
                fgColor={qrColor}
                level="H"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="success" onClick={handleDownload}>
              Download QR Code
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <div className="d-flex justify-content-between align-items-center my-4">
        <strong>Menu QR</strong>
        <button
          className="btn btn-outline-warning"
          onClick={handleGenerateQRCode}
          disabled={isLoading}
        >
          {isLoading && !isReadyToDownload ? 'Generating...' : !isLoading && !isReadyToDownload ? 'GENERATE QR CODE' : "View QR Code"}
        </button>
      </div>
    </div>
  );
}

export default QRCreator;
