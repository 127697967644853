import { apiSlice } from "../api/apiSlice";
import { setToken } from "../../../utils/authentication";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/auth/login",
        method: "POST",
        body
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = (await queryFulfilled);
          setToken(data.data.token);
          // localStorage.setItem("userData", JSON.stringify(data.data));
        } catch (error) {
          console.error(error, "login error");
        }
      }
    }),
    signUp: builder.mutation({
      query: (body) => ({
        url: "/auth/register",
        method: "POST",
        body
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = (await queryFulfilled);
          console.log(data);
          
          // console.log(data, "data")
          // setToken(data.data.token);
          // localStorage.setItem("userData", JSON.stringify(data.data));
        } catch (error) {
          // console.error(error, "login error");
        }
      }
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: "/estate-staffs/private/change-password",
        method: "PUT",
        body
      })
    }),
    forgotPassword: builder.query({
      query: (payload) => ({
        url: `/estate-staffs/public/forgot-password?emailAddress=${payload}`,
        method: "GET"
      })
    }),
    resetPasswordLink: builder.mutation({
      query: (email) => ({
        url: '/password-reset-link',
        method: 'POST',
        body: { email },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ email, password, password_confirmation, token }) => ({
        url: '/reset-password',
        method: 'POST',
        body: { email, password, password_confirmation, token },
      }),
    }),
    verifyInviteLink: builder.mutation({
      query: (payload) => ({
        url: "/estate-staffs/public/verify-account-invite",
        method: "POST",
        body: payload
      })
    }),
    getNewOtp: builder.query({
      query: (payload) => ({
        url: `/estate-staffs/public/otp?userId=${payload}`,
        method: "GET"
      })
    }),
    validateOtp: builder.mutation({
      query: (body) => ({
        url: "/estate-staffs/public/verify-login-otp",
        method: "POST",
        body
      })
    })
  })
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useForgotPasswordQuery,
  useChangePasswordMutation,
  useResetPasswordLinkMutation,
  useResetPasswordMutation,
  useVerifyInviteLinkMutation,
  useValidateOtpMutation,
  endpoints
} = authApi;
