import { jwtDecode } from "jwt-decode";

export const getToken = () => {
  const token = localStorage.getItem("access_token");
  return token ?? null;
};

export const setToken = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
};

export const decodeJwt = (token) => {
    return jwtDecode(token);
  };