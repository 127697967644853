import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
// import { Modal } from "react-bootstrap";
import {
  // IMAGES,
  SVGICON } from "../constant/theme";
import { endpoints } from "../../admin/redux/Orders/orders";
import Pagination from "../components/Pagination/Pagination";
import Table from "../components/Table/Table";
import ExportTransactions from "../components/ExportTransactions";
import { createSearchParams, useNavigate } from "react-router-dom";
import useGetUrlQuery from "../hooks/useGetUrlQuery";
import { useDispatch } from "react-redux";
import PrintReceiptModal from "../components/PrintReceiptModal";
import { formatDateTime } from "../../utils/formatDateTime";
import { toast } from "react-toastify";
import OrderDetailsModal from "../components/OrderDetailModal";

const MenuOrder = () => {
  const [detailModal, setDetailModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [orderType, setOrderType] = useState("dine-in");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const urlSearchParams = new URLSearchParams(window.location.search);

  const currentPage = Number(urlSearchParams.get("page")) || 1;
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
  const navigate = useNavigate();
  const urlQuery = useGetUrlQuery();
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    gen_order_id: { label: "Order ID", dbColumn: "gen_order_id", isChecked: false },
    table_number: { label: "Table Number", dbColumn: "table_number", isChecked: false },
    total_price: { label: "Total Price", dbColumn: "total_price", isChecked: false },
    waiters_name: { label: "Tipped Waiter", dbColumn: "waiters_name", isChecked: false },
    tipped_amount: { label: "Tipped Amount", dbColumn: "tipped_amount", isChecked: false },
    is_paid: { label: "Processed", dbColumn: "is_paid", isChecked: false },
    created_at: { label: "Transaction Date", dbColumn: "created_at", isChecked: false },
  });


  const getAllTransactions = useCallback(async () => {
    setIsFetching(true);
    try {
      const endpoint =
        orderType === "dine-in"
          ? endpoints.getDineInOrders
          : endpoints.getOnlineOrders;

      const response = await dispatch(
        endpoint.initiate({ page: currentPage })
      ).unwrap();
      console.log(response?.data?.data.order_details);
      setData(response?.data?.data);
      setTotalNumberOfPages(response?.data?.last_page);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setData([]);
      setTotalNumberOfPages(0);
    } finally {
      setIsFetching(false);
    }
  }, [currentPage, dispatch, orderType]);

  useEffect(() => {
    getAllTransactions();
  }, [currentPage, dispatch, getAllTransactions, orderType]);

  const handleProcessingChange = useCallback(
    async (order, newStatus) => {
      try {
        const updatedOrder = { ...order, is_processed: newStatus };

        const response = await dispatch(
          endpoints.updateProcessingOrders.initiate({
            id: order.id,
            data: updatedOrder,
          })
        ).unwrap();

        console.log('API Response:', response); // Log the API response

        if (response.status.toLowerCase === 'success') {
          setData((prevData) =>
            prevData.map((data) =>
              data.id === order.id ? { ...data, is_processed: newStatus } : data
            )
          );
          toast.success("Order processing status updated successfully!");
        } else {
          toast.error("Failed to update order processing status.");
        }
      } catch (error) {
        console.error("Error updating processing status:", error);
        toast.error("Failed to update order processing status.");
      }
    },
    [dispatch]
  );

  const handlePrintReceipt = async (id) => {
    try {
      const response = await dispatch(
        endpoints.printTransaction.initiate(id)
      ).unwrap();
      console.log(response);
      setSelectedReceipt(response.data);
      setPrintModalVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = (page) => {
    const query = { ...urlQuery, page: page };
    navigate(`?${createSearchParams(query).toString()}`);
  };

  const handleViewOrder = async (order) => {
    try {
      const updatedOrder = { ...order };

      const response = await dispatch(
        endpoints.viewProcessingOrders.initiate({
          id: order.id,
          data: updatedOrder // If your endpoint requires any specific data, include it here
        })
      ).unwrap();

      console.log('Order details:', response.status);

      if (response.status.toLowerCase() === 'success') {
        setSelectedOrder(response.data);
        setDetailModal(true); // Show the modal
      } else {
        toast.error("Failed to fetch order details.");
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
      toast.error("Failed to fetch order details.");
    }
  };

  const columns = [
    { header: "S/N", accessorFn: (_, index) => index + 1 },
    { header: "ORDER ID", accessorKey: "gen_order_id" },
    ...(orderType === "online"
      ? [
          { header: "PHONE NUMBER", accessorKey: "customer_phone" },
          { header: "DELIVERY LOCATION", accessorFn: (row) => row.delivery_address || "--" },
        ]
      : [
          { header: "TABLE NUMBER", accessorKey: "table_number" },
        ]),
    {
      header: "TOTAL PRICE",
      accessorFn: (row) => `₦${row.total_price}`,
    },
    ...(orderType === "dine-in"
      ? [
          {
            header: "TIPPED WAITER",
            accessorFn: (row) => row.waiters_name || "--",
          },
          {
            header: "TIPPED AMOUNT",
            accessorFn: (row) => `₦${row.tipped_amount || 0}`,
          },
          {
            header: "PROCESSED",
            cell: ({ row }) => (
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={row.original.is_processed}
                  onChange={() => handleProcessingChange(row.original, !row.original.is_processed)}
                />
              </div>
            ),
          },
        ]
      : []),
    {
      header: "TRANSACTION DATE",
      accessorFn: (row) => formatDateTime(row.created_at),
    },
    {
      header: "ACTION",
      cell: ({ row }) => (
        <div className="action-buttons d-flex justify-content-end">
          <button
            className="btn btn-info light me-2"
            onClick={() => handleViewOrder(row.original)}
          >
            View
          </button>
          <Link
            to="#"
            className="btn btn-success light"
            onClick={() => handlePrintReceipt(row.original.id)}
          >
            {SVGICON.Print}
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-md-flex d-block mb-5 align-items-start">
            <h2 className="dashboard-title me-auto">Order List</h2>
            <div className="d-flex align-items-center">
              <div className="btn-group me-3">
                <button
                  className={`btn ${
                    orderType === "dine-in"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                  onClick={() => setOrderType("dine-in")}
                >
                  Dine-in Orders
                </button>
                <button
                  className={`btn ${
                    orderType === "online"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                  onClick={() => setOrderType("online")}
                >
                  Online Orders
                </button>
              </div>
              <ExportTransactions selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <div
                  id="example6_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <Table
                    data={data}
                    columns={columns}
                    loading={isFetching}
                  />
                  <Pagination
                    totalNumberOfPages={totalNumberOfPages}
                    onPageClick={handlePageClick}
                    activePage={currentPage}
                    showSizePicker={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrintReceiptModal
        show={printModalVisible}
        onHide={() => setPrintModalVisible(false)}
        receipt={selectedReceipt}
      />
      <OrderDetailsModal
        show={detailModal}
        onHide={() => setDetailModal(false)}
        order={selectedOrder}
      />
    </>
  );
};

export default MenuOrder;
