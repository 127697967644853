import { apiSlice } from "../api/apiSlice";

// const token = localStorage.getItem("token");

export const menuListApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubCategories: builder.query({
      query: (query) => ({
        url: "/sub_categories",
        method: "GET",
        params: query,
      }),
    }),
    getCategories: builder.query({
      query: (query) => ({
        url: "/categories",
        method: "GET",
        params: query,
      }),
    }),
    getFoodMenus: builder.query({
      query: (query) => ({
        url: "/food_menus",
        method: "GET",
        params: query,
      }),
    }),
    getFoodMenusStatus: builder.mutation({
      query: ({ id, is_available }) => ({
        url: `/food_menus/update_status/${id}`,
        method: "PUT",
        body: { is_available },
      }),
    }),
    searchFoodMenus: builder.mutation({
      query: ({ search_item, search_value }) => ({
        url: `/food_menu_search?search_item=${search_item}&search_value=${search_value}`,
        method: "GET",
      }),
    }),
    updateFoodMenus: builder.mutation({
      query: ({ id, data }) => ({
        url: `/food_menus/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    uploadFoodMenus: builder.mutation({
      query: ({ data }) => ({
        url: "/food_menu_upload",
        method: "POST",
        body: data,
        headers: {
          'Content-Type': undefined
        },
      }),
    }),

    deleteFoodMenus: builder.query({
      query: (id) => ({
        url: `/food_menus/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetSubCategoriesQuery,
  useGetCategoriesQuery,
  useGetFoodMenusQuery,
  useUpdateFoodMenusMutation,
  useuploadFoodMenusMutation,
  useDeleteFoodMenusQuery,
  endpoints,
} = menuListApi;
