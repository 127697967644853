import React, { useState, useEffect } from 'react';
import { FaUpload } from 'react-icons/fa';
import { useFetchBrandQuery, useUpdateBrandMutation } from '../../admin/redux/Setup/setupApi';
import { toast, ToastContainer } from 'react-toastify'; // Import toastify for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS
import { useNavigate } from 'react-router-dom';

const BrandSetup = () => {
    const navigate = useNavigate();
    const { data: brand, isFetching, refetch } = useFetchBrandQuery(null, {
        extraOptions: {
          navigate, // Pass the navigate function for redirecting
        },
      });
    const [updateBrand] = useUpdateBrandMutation(); // mutation hook for updating brand info

    // Local state for brand logo and color
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null); // For logo preview
    const [brandColor, setBrandColor] = useState('#000000'); // Default color
    const brandData = JSON.parse(localStorage.getItem("brandData"));
    

    // Effect to set initial values from fetched brand data
    useEffect(() => {
        if (brand?.data) {
            setBrandColor(brand.data["0"]?.brand_color || '#000000');
            setLogoPreview(brand.data.image);
        }
    }, [brand]);

    // Handle logo upload and preview
    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogo(file);
            setLogoPreview(URL.createObjectURL(file)); // Preview the selected image
        }
    };

    // Handle brand color change
    const handleColorChange = (e) => {
        setBrandColor(e.target.value);
    };

    // Validate file type
    const isValidFileType = (type) => {
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        return validTypes.includes(type);
    };

    // Handle Update button click
    const handleUpdate = async () => {

        if (logo) {
            // Validate file type before proceeding
            if (!isValidFileType(logo.type)) {
                toast.error('Invalid file type. Please upload a valid image file.'); // Error toast
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(logo); // Convert image to base64

            reader.onloadend = async () => {
                const base64String = reader.result;
                const extension = logo.name.split('.').pop();
                const data = {
                    'brand_color' : brandColor,
                    'image' : base64String,
                    'extension': extension,
                    'brand_id' : brandData.id,
                    'imageUploaded': 1
                }
                try {
                    const response = await updateBrand(data).unwrap(); // Call the mutation to update the brand data
                    console.log(response);
                    
                    toast.success('Brand updated successfully!'); // Success toast
                    refetch(); // Refetch brand data to refresh the state
                } catch (error) {
                    console.error('Failed to update brand:', error);
                    toast.error('Failed to update brand. Please try again.'); // Error toast
                }
            };
        } else {
            // If no logo is uploaded, just update the color
            const data = {
                'brand_color' : brandColor,
                'brand_id' : brandData.id,
                'imageUploaded': 1
            }
            try {
                const response = await updateBrand(data).unwrap(); // Call the mutation to update the brand data
                console.log(response);
                toast.success('Brand color updated successfully!'); // Success toast
                refetch(); // Refetch brand data to refresh the state
            } catch (error) {
                console.error('Failed to update brand:', error);
                toast.error('Failed to update brand. Please try again.'); // Error toast
            }
        }
    };

    return (
        <div className="container">
            <h3 className="mb-4">BRAND SETUP</h3>
            {isFetching ? <h4>Loading...</h4> :
                <>
                    <div className="row">
                        {/* Add Logo Section */}
                        <div className="col-md-6">
                            <label className="form-label"><strong>Add Logo</strong></label>
                            <div className="mb-3">
                                {logoPreview ? (
                                    <div style={{ width: '150px', height: '150px', marginBottom: '10px' }}>
                                        <img
                                            src={logoPreview}
                                            alt="Logo Preview"
                                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                        />
                                    </div>
                                ) : (
                                    <div className="border" style={{ width: '150px', height: '150px', marginBottom: '10px' }}>
                                        <p className="text-center" style={{ lineHeight: '150px' }}>No Logo</p>
                                    </div>
                                )}
                                <label className="btn btn-primary" htmlFor="logo-upload">
                                    <FaUpload /> Add Logo
                                </label>
                                <input
                                    type="file"
                                    id="logo-upload"
                                    accept="image/*"
                                    onChange={handleLogoChange}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>

                        {/* Add Brand Color Section */}
                        <div className="col-md-6">
                            <div className="card p-3 shadow">
                                <label className="form-label"><strong>Add Brand Color</strong></label>
                                <input
                                    type="color"
                                    className="form-control"
                                    value={brandColor}
                                    onChange={handleColorChange}
                                    style={{ height: '50px', cursor: 'pointer' }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Update Button */}
                    <div className="d-flex mt-4 mb-4">
                        <button className="btn btn-warning" onClick={handleUpdate}>
                            Update
                        </button>
                    </div>
                </>
            }
            {/* Toast Container for notifications */}
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
        </div>
    );
};

export default BrandSetup;
