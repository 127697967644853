import React, { useState } from 'react';
import { useUpdateHomepageImageMutation } from '../../admin/redux/Setup/setupApi';
import { toast } from 'react-toastify';

const HomepageImage = () => {
  const [imagePreview, setImagePreview] = useState(null); // For displaying the image preview
  const [error, setError] = useState(''); // For displaying errors
  const [homepageImage] = useUpdateHomepageImageMutation();

  // Validation functions
  const isValidFileType = (type) => ['image/jpeg', 'image/png', 'image/gif'].includes(type);
  const isValidFileSize = (size) => size <= 2 * 1024 * 1024; // 2MB limit

  const handleAddImage = async (e) => {
    const file = e.target.files[0];

    // Validate file type
    if (!isValidFileType(file.type)) {
      setError('Invalid file type. Please upload a valid image file.');
      return;
    }

    // Validate file size
    if (!isValidFileSize(file.size)) {
      setError('File size exceeds the limit. Please upload a smaller image file.');
      return;
    }

    // Reset error if validation passes
    setError('');

    // Generate a preview for the user
    const preview = URL.createObjectURL(file);
    setImagePreview(preview);

    // Convert file to base64 and call the mutation
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const base64Image = reader.result;
      const extension = file.name.split('.').pop(); // Get file extension
      // const brand_id = localStorage.getItem("brand_id"); // Get brand_id from local storage

      const data = {
        image: base64Image, // Base64 string
        extension: extension, // File extension
        brand_id: 5 // Brand ID
      };

      try {
        const response = await homepageImage(data).unwrap(); // Unwrap the promise to get the response
        console.log(response);
        
        toast.success('Image uploaded successfully!'); // Display success toast
      } catch (err) {
        console.error('Upload error:', err);
        toast.error('Failed to upload the image.'); // Display error toast
        if (err.status === 400) {
          setError('Invalid request. Please check your data.');
        } else if (err.status === 500) {
          setError('Server error. Please try again later.');
        } else {
          setError('Failed to upload the image.');
        }
      }
    };
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <p className='me-2'><strong>Homepage Image</strong></p>
        <div className="col-md-3">
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Homepage Preview"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          )}
        </div>
        <div>
          <input
            type="file"
            id="homepageImage"
            className="form-control"
            accept="image/*"
            onChange={handleAddImage}
            style={{ display: 'none' }}
          />
          <label htmlFor="homepageImage" className="btn btn-warning">
            Add Image
          </label>
        </div>
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

export default HomepageImage;
