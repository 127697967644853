import React, { Fragment } from "react";
import ContentLoader from "react-content-loader";
import PropTypes from "prop-types";

const Skeleton = ({ rows = 5, rowHeight = 50, ...props }) => {
  return (
    <ContentLoader viewBox={`0 0 1500 ${rowHeight * rows}`} {...props}>
      {new Array(rows).fill(" ").map((_, index) => {
        const contentVerticalPosition = (contentHeight) =>
          rows > 1 ? contentHeight + rowHeight * index : contentHeight;
        return (
          <Fragment key={index}>
            <rect x='0' y={`${contentVerticalPosition(20)}`} rx='4' ry='4' width='1500' height='30' />
            <rect y={`${contentVerticalPosition(59)}`} x='0' ry='10' width='1500' height='1' />
          </Fragment>
        );
      })}
    </ContentLoader>
  );
};

Skeleton.propTypes = {
  rows: PropTypes.number,
  rowHeight: PropTypes.number
};

export default Skeleton;
