/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { userRegForm } from "../../redux/Auth/regSlice";
import { validateForm } from "../../../utils/regValidation";
import { debounce } from "../../../utils/debounce";
import { useSignUpMutation } from "../../redux/Auth/authApi";
import { encryptPassword } from "../../../utils/encryption";
import { login } from "../../redux/api/authSlice";

const countryCurrencyMap = {
  Nigeria: "₦",
  UK: "£",
  USA: "$",
  Ghana: "₵",
};

const Register = () => {
  const navigate = useNavigate();
  const [signUp] = useSignUpMutation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    full_name: "",
    business_name: "",
    email: "",
    state: "",
    street: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
    user_type_id: 1,
    country: "",
    currency: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordView = () => setShowPassword((prev) => !prev);

  // Debounced form validation function
  const validateOnInputChange = useCallback(
    debounce((newFormData) => {
      const validationErrors = validateForm(newFormData);
      setErrors(validationErrors);
    }, 500), // 500ms delay
    []
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "country") {
      const selectedCurrency = countryCurrencyMap[value] || "";
      setFormData((prevData) => ({
        ...prevData,
        country: value,
        currency: selectedCurrency,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // Trigger debounced validation only for form inputs
    validateOnInputChange({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const encryptedPassword = encryptPassword(formData.password);
    const encryptedPasswordConfirmation = encryptPassword(
      formData.password_confirmation
    );

    const finalFormData = {
      ...formData,
      password: encryptedPassword,
      password_confirmation: encryptedPasswordConfirmation,
    };

    const newErrors = validateForm(finalFormData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Please fix the highlighted errors.");
      return;
    }

    dispatch(userRegForm(finalFormData));

    try {
      const result = await signUp(finalFormData).unwrap();
      toast.success(result?.message);
      dispatch(login(result.data.token));
      navigate("/login");
    } catch (err) {
      toast.error(err.data?.message);
    }
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">Sign up your account</h4>
                    <form onSubmit={handleSubmit}>
                      <InputField
                        label="Full Name"
                        value={formData.full_name}
                        name="full_name"
                        onChange={handleChange}
                        error={errors.full_name}
                      />
                      <InputField
                        label="Business Name"
                        value={formData.business_name}
                        name="business_name"
                        onChange={handleChange}
                        error={errors.business_name}
                      />
                      <InputField
                        label="Email"
                        value={formData.email}
                        name="email"
                        onChange={handleChange}
                        error={errors.email}
                      />
                      <label className="mb-1">
                        <strong>Country</strong>
                      </label>
                      <select
                        className={`form-control ${
                          errors.country ? "is-invalid" : ""
                        }`}
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                      >
                        <option value="">Select Country</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="UK">UK</option>
                        <option value="USA">USA</option>
                        <option value="Ghana">Ghana</option>
                      </select>
                      {errors.country && (
                        <div className="invalid-feedback">{errors.country}</div>
                      )}

                      <InputField
                        label="State"
                        value={formData.state}
                        name="state"
                        onChange={handleChange}
                        error={errors.state}
                      />
                      <InputField
                        label="Street"
                        value={formData.street}
                        name="street"
                        onChange={handleChange}
                        error={errors.street}
                      />
                      <InputField
                        label="Phone Number"
                        value={formData.phone_number}
                        name="phone_number"
                        onChange={handleChange}
                        error={errors.phone_number}
                      />
                      <PasswordField
                        label="Password"
                        value={formData.password}
                        name="password"
                        onChange={handleChange}
                        showPassword={showPassword}
                        togglePasswordView={togglePasswordView}
                        error={errors.password}
                      />
                      <PasswordField
                        label="Confirm Password"
                        value={formData.password_confirmation}
                        name="password_confirmation"
                        onChange={handleChange}
                        showPassword={showPassword}
                        togglePasswordView={togglePasswordView}
                        error={errors.password_confirmation}
                      />
                      <SubmitButton label="Submit" />
                    </form>

                    <div className="new-account mt-3">
                      <p>
                        Already have an account?
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InputField = ({
  label,
  value,
  name,
  onChange,
  error,
  disabled = false,
}) => (
  <div className="form-group">
    <label className="mb-1">
      <strong>{label}</strong>
    </label>
    <input
      type="text"
      className={`form-control ${error ? "is-invalid" : ""}`}
      value={value}
      name={name}
      onChange={onChange}
      disabled={disabled}
    />
    {error && <div className="invalid-feedback">{error}</div>}
  </div>
);

const PasswordField = ({
  label,
  value,
  name,
  onChange,
  showPassword,
  togglePasswordView,
  error,
}) => (
  <div className="form-group">
    <label className="mb-1">
      <strong>{label}</strong>
    </label>
    <div className="input-group">
      <input
        type={showPassword ? "text" : "password"}
        className={`form-control ${error ? "is-invalid" : ""}`} // Highlight input if there's an error
        value={value}
        name={name}
        onChange={onChange}
      />
      <span
        className="input-group-text"
        onClick={togglePasswordView}
        style={{ cursor: "pointer" }}
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </span>
    </div>
    {/* Display error message right after the input group */}
    {error && <div className="invalid-feedback d-block">{error}</div>}
  </div>
);

const SubmitButton = ({ label, disabled }) => (
  <div className="text-center mt-4">
    <input
      type="submit"
      className="btn btn-primary btn-block"
      value={label}
      disabled={disabled}
    />
  </div>
);

export default Register;
