import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Toggle from "./ToggleButton/ToggleButton";
import {
  useGetDeliveriesQuery,
  useAddDeliveryMutation,
  useEditDeliveryMutation,
  useDeleteDeliveryMutation,
} from "../../admin/redux/Setup/setupApi";
import { SVGICON } from "../constant/theme";

const DeliveryOptions = () => {
  const { data: deliveries, isLoading, isError } = useGetDeliveriesQuery();
  const [addDelivery] = useAddDeliveryMutation();
  const [editDelivery] = useEditDeliveryMutation();
  const [deleteDelivery] = useDeleteDeliveryMutation();

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [action, setAction] = useState(null);
  const [isToggled, setIsToggled] = useState(false);
  console.log(isToggled);

  const handleToggleChange = async (id, newValue) => {
    setIsToggled(newValue);
    try {
      await editDelivery({ id, enabled: newValue ? 1 : 0 }).unwrap();
      console.log("Toggle updated:", newValue);
    } catch (error) {
      console.error("Failed to update toggle:", error);
    }
  };

  const openModal = (data, action) => {
    setModal(true);
    setModalData(data);
    setAction(action);
  };

  const handleDelete = async (id) => {
    try {
      await deleteDelivery(id);
      setModal(false);
    } catch (error) {
      console.error("Failed to delete delivery:", error);
    }
  };

  const handleEdit = async (id, updatedData) => {
    try {
      const res = await editDelivery({ id, ...updatedData }).unwrap();
      console.log(res);
      setModal(false);
    } catch (error) {
      console.error("Failed to edit delivery:", error);
    }
  };

  const handleAddDelivery = async (newDelivery) => {
    if (!newDelivery.location || !newDelivery.delivery_fee) {
      console.error("Please provide valid input");
      return;
    }
    try {
      await addDelivery(newDelivery);
      setModal(false);
    } catch (error) {
      console.error("Failed to add delivery:", error);
    }
  };

  const renderModalContent = () => {
    if (action === "edit") {
      return (
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleEdit(modalData.id, modalData);
            }}
          >
            <input
              type="text"
              className="form-control"
              value={modalData?.location || ""}
              onChange={(e) =>
                setModalData({ ...modalData, location: e.target.value })
              }
            />
            <input
              type="number"
              className="form-control mt-3"
              value={modalData?.delivery_fee || ""}
              onChange={(e) =>
                setModalData({
                  ...modalData,
                  delivery_fee: parseFloat(e.target.value),
                })
              }
            />
            <button type="submit" className="btn btn-primary mt-3">
              Save Changes
            </button>
          </form>
        </div>
      );
    }

    if (action === "delete") {
      return (
        <div className="modal-body">
          <h5>Are you sure you want to delete this delivery?</h5>
          <button
            className="btn btn-danger mt-3"
            onClick={() => handleDelete(modalData.id)}
          >
            Yes, Delete
          </button>
        </div>
      );
    }

    if (action === "add") {
      return (
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddDelivery(modalData);
            }}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Location"
              value={modalData?.location || ""}
              onChange={(e) =>
                setModalData({ ...modalData, location: e.target.value })
              }
            />
            <input
              type="number"
              className="form-control mt-3"
              placeholder="Price"
              value={modalData?.delivery_fee || ""}
              onChange={(e) =>
                setModalData({
                  ...modalData,
                  delivery_fee: parseFloat(e.target.value),
                })
              }
            />
            <button type="submit" className="btn btn-primary mt-3">
              Add Delivery
            </button>
          </form>
        </div>
      );
    }

    return null;
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Failed to load deliveries</div>;

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center my-4">
        <h3 className="mb-4">DELIVERY OPTIONS</h3>
        <button
          className="btn btn-warning mb-4"
          onClick={() => openModal({}, "add")}
        >
          ADD DELIVERY
        </button>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table mb-4">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {deliveries?.data.map((data) => (
                  <tr key={data.id}>
                    <td>{data.location}</td>
                    <td>₦ {data.delivery_fee}</td>
                    <td>
                      <Toggle
                        defaultChecked={data.enabled === 1}
                        onChange={(newValue) =>
                          handleToggleChange(data.id, newValue)
                        }
                      />
                    </td>
                    <td>
                      <div className="action-buttons d-flex">
                        <Link
                          to={"#"}
                          className="btn btn-primary light me-2"
                          data-bs-toggle="modal"
                          onClick={() => openModal(data, "edit")}
                        >
                          {SVGICON.Edit}
                        </Link>
                        <Link
                          to={"#"}
                          className="btn btn-danger light"
                          data-bs-toggle="modal"
                          onClick={() => openModal(data, "delete")}
                        >
                          {SVGICON.Delete}
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        className="modal order-info-box"
        id="OrderInfoBox"
        show={modal}
        onHide={() => setModal(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {action === "add"
              ? "Add New Delivery"
              : action === "edit"
              ? "Edit Delivery"
              : "Delete Delivery"}
          </h5>
        </div>
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default DeliveryOptions;
