// // This is for temporary use only.
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// // initialize an empty api service that we'll inject endpoints into later as needed
// export const apiSlice = createApi({
//   baseQuery: fetchBaseQuery({
//     baseUrl: process.env.REACT_APP_BASE_URL,
//     prepareHeaders: (headers) => {
//       const token = localStorage.getItem("token");

//       // headers.set("x-api-key", import.meta.env.VITE_X_API_KEY as string);
//       headers.set("Content-Type", "application/json")
//       headers.set( "Accept", "application/json")
    
//       if (token) {
//         headers.set("authorization", `Bearer ${token.replace(/['"]+/g, "")}`);
//       }

//       return headers;
//     }
//   }),
//   endpoints: () => ({})
// });

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout } from './authSlice'; // Assuming you have an authSlice with a logout action

// Define the base query
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");

    // Set the required headers
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");

    // Attach the token if available
    if (token) {
      headers.set("authorization", `Bearer ${token.replace(/['"]+/g, "")}`);
    }

    return headers;
  }
});

// Enhanced base query to handle 401 errors globally
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
console.log("result", result.error);

  if (result.error && result.error.status === 401) {
    console.log("here");
    // localStorage.clear()
    // Token expired or unauthorized
    api.dispatch(logout()); // Dispatch the logout action
    if (extraOptions && extraOptions.navigate) {
      extraOptions.navigate('/login');
    }
  }

  return result;
};

// Create API slice
export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth, // Use the enhanced base query
  endpoints: () => ({}), // You can inject endpoints here as needed
});
