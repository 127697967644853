import { useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../admin/redux/api/authSlice';

const INACTIVITY_LIMIT = 20 * 60 * 1000; // 20 minutes

export const useInactivityTimer = () => {
  const dispatch = useDispatch();
  const timeoutRef = useRef(null); // Use useRef to hold the timeout ID

  const resetTimer = useCallback(() => {
    // Clear the previous timeout
    clearTimeout(timeoutRef.current);
    // Set a new timeout and store its ID in the ref
    timeoutRef.current = setTimeout(() => {
      dispatch(logout()); // Dispatch the logout action
      alert("You've been logged out due to inactivity.");
    }, INACTIVITY_LIMIT);
  }, [dispatch]);

  useEffect(() => {
    // Set event listeners for user activities
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('click', resetTimer);
    window.addEventListener('scroll', resetTimer);

    // Reset the timer initially
    resetTimer();

    // Cleanup event listeners on unmount
    return () => {
      clearTimeout(timeoutRef.current); // Clear the timeout on unmount
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('scroll', resetTimer);
    };
  }, [resetTimer]); // Only include 'resetTimer' in the dependency array
};
