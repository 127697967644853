import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaChevronCircleLeft } from 'react-icons/fa';


const CategoryDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { category } = location.state; // Access the passed category data
    console.log(category);
    

    // Function to handle going back to the previous screen
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className="content-wrapper">
        <div className="container-fluid">
            {/* Back Button */}
            <div className="d-flex align-items-center mb-4">
                    <FaChevronCircleLeft />
                <button className="btn btn-link text-dark" onClick={handleGoBack}>
                    <h4>
                        Back
                    </h4>
                </button>
            </div>

            {/* Category Title and Add Subcategory Button */}
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="fw-bold">
                    {category.category.charAt(0).toUpperCase() + category.category.slice(1)} Category
                </h3>
                <button className="btn btn-warning text-white fw-bold">
                    ADD SUB CATEGORY
                </button>
            </div>

            {/* Sub Category Table Header */}
            <div className="border-bottom mb-2">
                <p className="fw-bold">Sub Category</p>
            </div>

            {/* Placeholder for Sub Category Data */}
            <div className="border-bottom mb-2">
                <p>No subcategories available</p>
            </div>
        </div>
        </div>
    );
};

export default CategoryDetails;
