import React, { useState } from 'react';
import { Dropdown, Button, Form } from 'react-bootstrap';

const ExportTransactionsDropdown = ({handleShow, selectedFilters, setSelectedFilters}) => {

  const [showDropdown, setShowDropdown] = useState(false);

  const handleFilterChange = (filterKey) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: {
        ...prevFilters[filterKey],
        isChecked: !prevFilters[filterKey].isChecked
      }
    }));
  };
  

  // const handleExport = () => {
  //   console.log('Selected Filters:', selectedFilters);
  //   setShowDropdown(false)
  // };

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  return (
<Dropdown show={showDropdown} onToggle={toggleDropdown}>
  <Dropdown.Toggle as={Button} variant="primary" onClick={toggleDropdown}>
    Export Transactions
  </Dropdown.Toggle>

  <Dropdown.Menu>
    {Object.keys(selectedFilters).map((filterKey) => (
      <Dropdown.Item as="button" key={filterKey}>
        <Form.Check 
          type="checkbox" 
          label={selectedFilters[filterKey].label} 
          checked={selectedFilters[filterKey].isChecked} 
          onChange={() => handleFilterChange(filterKey)}
          onClick={(e) => e.stopPropagation()} 
        />
      </Dropdown.Item>
    ))}
    <Dropdown.Divider />
    <Button variant="primary" onClick={() => {
      setShowDropdown(false)
      handleShow()
    }}>
      Export Transactions
    </Button>
  </Dropdown.Menu>
</Dropdown>

  );
};

export default ExportTransactionsDropdown;