import { apiSlice } from "../api/apiSlice";
import { getToken } from "../../../utils/authentication"; // Utility to get token from storage

export const setupApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
     // Fetch User Data
     fetchUserData: builder.query({
      query: () => ({
        url: "/users",
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
    }),

     // Fetch Brand
     fetchBrand: builder.query({
      query: () => ({
        url: "/brand_designs",
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
    }),

    updateBrand: builder.mutation({
      query: (formData) => ({
        url: "/brand_design_update",
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        body: formData
      })
    }),

    // Update Homepage Image
    updateHomepageImage: builder.mutation({
      query: (data) => ({
        url: `/home_page_image`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        body: data
      })
    }),

    // Fetch Super Categories
    fetchSuperCategory: builder.query({
      query: () => ({
        url: "/get_super_categories",
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
    }),
    
    // Fetch Categories
    fetchCategory: builder.query({
      query: () => ({
        url: "/categories",
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
    }),

    // Fetch Subcategories
    fetchSubCategory: builder.query({
      query: (id) => ({
        url: `/sub_category_list/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
    }),
    
    // Add Category
    addCategory: builder.mutation({
      query: (data) => ({
        url: "/categories",
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        body: data
      })
    }),

    // Update Category
    updateCategory: builder.mutation({
      query: ({ categoryId, body }) => ({
        url: `/update_category/${categoryId}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        body
      })
      
    }),

    // Delete Category
    deleteCategory: builder.mutation({
      query: (categoryId) => ({
        url: `/categories/${categoryId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
    }),
    
    // Add Subcategory
    addSubCategory: builder.mutation({
      query: (body) => ({
        url: "/sub_categories",
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        body
      })
    }),

    // Update Subcategory
    updateSubCategory: builder.mutation({
      query: ({ subCategoryId, body }) => ({
        url: `/sub_categories/${subCategoryId}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        body
      })
    }),

    // Delete Subcategory
    deleteSubCategory: builder.mutation({
      query: (subCategoryId) => ({
        url: `/sub_categories/${subCategoryId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
    }),


    getDeliveries: builder.query({
      query: () => ({
        url: "/delivery_charges",
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
    }),


    addDelivery: builder.mutation({
      query: (newDelivery) => ({
        url: 'delivery_charges',
        method: 'POST',
        body: newDelivery,
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      }),
    }),


    editDelivery: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `delivery_charges/${id}`,
        method: 'PATCH',
        body: patch,
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      }),
    }),


    deleteDelivery: builder.mutation({
      query: (id) => ({
        url: `delivery_charges/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      }),
    }),
    // Fetch Categories
    generateQRCode: builder.mutation({
      query: (qrType) => ({
        url: '/generate-qr',
        method: 'POST',
        body: { qrType },
      }),
    }),
    
    updatePassword: builder.mutation({
      query: ({ userID, new_password, new_confirm_password }) => ({
        url: `/users/password/${userID}`,
        method: 'PUT',
        body: { new_password, new_confirm_password },
      }),
    }),
  })
});

// Exporting the hooks to use in components
export const {
  useFetchUserDataQuery,
  useFetchBrandQuery,
  useFetchSuperCategoryQuery,
  useFetchCategoryQuery,
  useFetchSubCategoryQuery,
  useUpdateBrandMutation,
  useUpdateHomepageImageMutation,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useAddSubCategoryMutation,
  useUpdateSubCategoryMutation,
  useDeleteSubCategoryMutation,
  useGetDeliveriesQuery,
  useAddDeliveryMutation,
  useEditDeliveryMutation,
  useDeleteDeliveryMutation,
  useGenerateQRCodeMutation,
  useUpdatePasswordMutation
} = setupApi;
