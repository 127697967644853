import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('token') || null,
  isAuthenticated: false,
  user: null,
  brandData: {},
  brandImage: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      state.user = null
      state.brandData = {}
      state.brandImage = null
      // localStorage.clear();
      // No navigation here, just clear the token
    },
    login: (state, action) => {
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.brandData = action.payload.brand_design.original.data
      state.brandImage = action.payload.brand_design.original.image
    }
  },
});

export const { logout, login } = authSlice.actions;

export default authSlice.reducer;
