import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Toggle from './ToggleButton/ToggleButton';
import { Link } from 'react-router-dom';
import { SVGICON } from '../constant/theme';
import { useUpdatePasswordMutation } from '../../admin/redux/Setup/setupApi';
import { Button, Modal } from 'react-bootstrap';
import QRCreator from './QRCode';
import axios from 'axios';
// import { QRCodeCanvas } from 'qrcode.react';
import { encryptPassword } from '../../utils/encryption';
import { toast } from 'react-toastify';

const MerchantSettings = () => {
  const [modal, setModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for toggling confirm password visibility
  const [foodMenuViewOnly, setFoodMenuViewOnly] = useState(false);
  const [foodMenuOrder, setFoodMenuOrder] = useState(false);
  const [qrcode, setQrcode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [bank, setBank] = useState("")

  const userData = JSON.parse(localStorage.getItem("userData"));

  const downloadQRCode = () => {
    if (qrcode) {
      const link = document.createElement('a');
      link.href = qrcode; // Set the href to the base64 QR code
      link.download = 'qrcode.png'; // Set the default filename
      document.body.appendChild(link); // Append the link to the body
      link.click(); // Programmatically click the link to trigger the download
      document.body.removeChild(link); // Remove the link after downloading
    }
  };

  // Mutation hooks for generating QR code and updating password
  // const [generateQRCode, { isLoading: qrLoading }] = useGenerateQRCodeMutation();
  const [updatePassword, { isLoading: passwordLoading }] = useUpdatePasswordMutation();
  const [isQR, setIsQR] = useState(false);

  useEffect(() => {
    if (userData) {
      setFoodMenuOrder(userData.order_select === 1);
      setFoodMenuViewOnly(userData.is_locked === 1);
    }
  }, [userData]);

  const toggleModal = (action) => {
    setModal(true);
    if (action === "qrcode") {
      setIsQR(true);
    } else {
      setIsQR(false);
    }
  };

  useEffect(() => {
    const isShawarma = localStorage.getItem("isShawarma");
    const isLocalEat = localStorage.getItem("isLocalEat");
    setBank((isShawarma === 'true' || isLocalEat === 'true') ? 'providus' : 'zenith');
  }, []);
console.log(userData);

  const handleGenerateQRCode = async () => {
    try {
      setIsLoading(true);

      // const isShawarma = localStorage.getItem("isShawarma");
      // const isLocalEat = localStorage.getItem("isLocalEat");

      // const bank = (isShawarma === 'true' || isLocalEat === 'true') ? 'providus' : 'zenith';

      const data = {
        merchantId: userData.qrpay_mid,
        discount: userData.discount,
        app: 'restaurant',
        NPmerchantId: userData.merchant_id,
        image: userData.imageData,
        isZenith: userData.isZenith,
        isBank: bank
      };

      const headers = {
        'Content-Type': 'application/json',
        "Accept": "application/json",
      };

      const result = await axios.post('https://qrapi.paysaddle.com/qrcode', data, { headers });
      setQrcode("data:image/png;base64," + result.data[0]);
      setIsLoading(false);
    } catch (err) {
      setError(err.response?.statusText || 'Failed to generate QR code. Please try again.');
      setIsLoading(false);
    }
  };

  const handlePasswordUpdate = async (event) => {
    event.preventDefault();
    
    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
  
    const encryptedPassword = encryptPassword(password);
    console.log(encryptedPassword);
    
    try {
      await updatePassword({ userID: userData.id, new_password: encryptedPassword, new_confirm_password: encryptedPassword }).unwrap();
      toast('Password updated successfully!');
      setModal(false); // Close modal after success
    } catch (error) {
      console.error('Password update failed', error);
      setError('Failed to update password. Please try again.');
    }
  };
  const handleToggleFoodMenuViewOnly = () => {
    setFoodMenuViewOnly(!foodMenuViewOnly);
  };

  const handleToggleFoodMenuOrder = () => {
    setFoodMenuOrder(!foodMenuOrder);
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center my-4">
        <strong>Password Settings</strong>
        <button className="btn btn-warning" onClick={() => toggleModal('password')}>
          Update Password
        </button>
      </div>

      <QRCreator />

      {error && <div className="alert alert-danger">{error}</div>}

      <div className="d-flex justify-content-between align-items-center my-4">
        <strong>Payment Only QR</strong>
        <button
          className="btn btn-outline-primary"
          onClick={() => !isLoading && (!qrcode ? handleGenerateQRCode() : toggleModal("qrcode"))}
          disabled={isLoading}
        >
          {isLoading ? 'Generating...' : qrcode ? 'View QR' : 'GENERATE QR CODE'}
        </button>
      </div>

      <div className="row mb-4">
        <div className="col-md-6">
          <strong>FOOD MENU OPTIONS</strong>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center my-4">
        <span>Food Menu View Only:</span>
        <Toggle
          defaultChecked={foodMenuViewOnly}
          onChange={handleToggleFoodMenuViewOnly}
        />
      </div>

      <div className="d-flex justify-content-between align-items-center my-4">
        <span>Food Menu View and Order:</span>
        <Toggle
          defaultChecked={foodMenuOrder}
          onChange={handleToggleFoodMenuOrder}
        />
      </div>

      {/* Wait Time */}
      <div className="d-flex justify-content-between align-items-center my-4">
        <p className="mb-0"><strong>Wait Time:</strong> {userData?.average_time} mins</p>
        <div className="action-buttons d-flex">
          <Link to={"#"} className="btn btn-primary light me-2" onClick={() => toggleModal("edit")}>
            {SVGICON.Edit}
          </Link>
          <Link to={"#"} className="btn btn-danger light" onClick={() => toggleModal("delete")}>
            {SVGICON.Delete}
          </Link>
        </div>
      </div>

      {/* Food Pack Price */}
      <div className="d-flex justify-content-between align-items-center my-4">
        <p className="mb-0"><strong>Food Pack Price:</strong> ₦ {parseFloat(userData?.pack_price).toFixed(2)}</p>
        <div className="action-buttons d-flex">
          <Link to={"#"} className="btn btn-primary light me-2" onClick={() => toggleModal("edit")}>
            {SVGICON.Edit}
          </Link>
          <Link to={"#"} className="btn btn-danger light" onClick={() => toggleModal("delete")}>
            {SVGICON.Delete}
          </Link>
        </div>
      </div>

      <Modal show={isQR && modal} onHide={() => { setModal(false); setIsQR(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
    {qrcode ? (
      <img
        src={qrcode} // Use base64 image as the source
        alt="Generated QR Code"
        style={{ width: '100%', height: 'auto' }} // Adjust width and height as needed
      />
    ) : (
      <p>QR Code not available</p>
    )}
  </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal(false)}>
            Close
          </Button>
          <Button variant="success" onClick={downloadQRCode}>
            Download QR Code
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modal && !isQR} onHide={() => setModal(false)}>
        <div className="modal-header">
          <h5 className="user-name">Change Password</h5>
        </div>
        <div className="modal-body">
          <form onSubmit={handlePasswordUpdate}>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="input-group-text"
                  onClick={() => setShowPassword(!showPassword)} // Toggle visibility
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <div className="input-group">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  id="confirmPassword"
                  placeholder="confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span
                  className="input-group-text"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle visibility
                  style={{ cursor: "pointer" }}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>
                Close
              </button>
              <button type="submit" className="btn btn-primary" disabled={passwordLoading}>
                {passwordLoading ? "Updating..." : "Update Password"}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default MerchantSettings;
