/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { SVGICON } from "../constant/theme";
import { endpoints } from "../../admin/redux/MenuList/menuList";
import { useDispatch } from "react-redux";
import Pagination from "../components/Pagination/Pagination";
import Table from "../components/Table/Table";
import { createSearchParams, useNavigate } from "react-router-dom";
import useGetUrlQuery from "../hooks/useGetUrlQuery";
import PrintReceiptModal from "../components/PrintReceiptModal";
import { toast } from "react-toastify";
import Toggle from "../components/ToggleButton/ToggleButton";
import FoodMenuForm from "./FoodMenuForm";
import axios from "axios";
import BulkFoodMenuUpload from "./BulkFoodMenuUpload";

const FrontFoodItems = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formMode, setFormMode] = useState("add");
  const [selectedFoodItem, setSelectedFoodItem] = useState(null);
  const [searchItem, setSearchItem] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [showBulkUpload, setShowBulkUpload] = useState(false);

  const currentPage = Number(urlSearchParams.get("page")) || 1;
  const [printModalVisible, setPrintModalVisible] = useState(false);
  // const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
  const navigate = useNavigate();
  const urlQuery = useGetUrlQuery();
  const dispatch = useDispatch();

  const handleAddNew = () => {
    setFormMode("add");
    setSelectedFoodItem(null);
    setIsFormOpen(true);
  };

  const handleUpdateFoodMenu = (id) => {
    const foodItem = data.find((item) => item.id === id);
    setSelectedFoodItem(foodItem);
    setFormMode("edit");
    setIsFormOpen(true);
  };

  useEffect(() => {
    if (!isSearchActive) {
      getAllTransactions();
    }
  }, [currentPage, isSearchActive]);

  const getAllTransactions = async () => {
    setIsFetching(true);
    try {
      const response = await dispatch(
        endpoints.getFoodMenus.initiate(
          { page: currentPage },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      setData(response?.data?.data);
      setTotalNumberOfPages(response?.data?.last_page);
    } catch (error) {
      setData([]);
      setTotalNumberOfPages(0);
      toast.error("Error fetching food menus");
    } finally {
      setIsFetching(false);
    }
  };

  const handlePageClick = (page) => {
    const query = { ...urlQuery, page: page };
    navigate(`?${createSearchParams(query).toString()}`);
  };

  const handleAvailabilityToggle = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      await dispatch(
        endpoints.getFoodMenusStatus.initiate({
          id: id.toString(),
          is_available: newStatus,
        })
      ).unwrap();

      setData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, is_available: newStatus } : item
        )
      );

      toast.success("Availability status updated successfully");
    } catch (error) {
      console.error("Error toggling availability:", error);
      toast.error("Error updating availability status");
    }
  };

  const handleDeleteFoodMenu = async (id) => {
    try {
      await dispatch(endpoints.deleteFoodMenus.initiate(id)).unwrap();
      setData((prevData) => prevData.filter((item) => item.id !== id));
      toast.success("Food menu deleted successfully");
    } catch (error) {
      console.error("Error deleting food menu:", error);
      toast.error("Error deleting food menu");
    }
  };

  const handleSaveFoodMenu = async (formData) => {
    try {
      const token = localStorage.getItem("token"); // Assuming you store the token in localStorage

      if (formMode === "add") {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/food_menus`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token.replace(/['"]+/g, "")}`,
            },
          }
        );
      } else {
        await axios.put(
          `${process.env.REACT_APP_BASE_URL}/food_menus/${selectedFoodItem.id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.replace(/['"]+/g, "")}`,
            },
          }
        );
      }
      toast.success(
        `Food menu ${formMode === "add" ? "added" : "updated"} successfully`
      );
      setIsFormOpen(false);
      getAllTransactions();
    } catch (error) {
      console.error("Error saving food menu:", error);
      toast.error(
        error.response?.data?.message ||
          `Error ${formMode === "add" ? "adding" : "updating"} food menu`
      );
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    setIsSearchActive(true);
    try {
      const response = await dispatch(
        endpoints.searchFoodMenus.initiate({
          search_item: searchItem,
          search_value: searchValue,
        })
      ).unwrap();
      setData(response?.data?.data || []);
      setTotalNumberOfPages(response?.data?.last_page || 1);
    } catch (error) {
      console.error("Error searching food menus:", error);
      toast.error("Error searching food menus");
      setData([]);
      setTotalNumberOfPages(0);
    } finally {
      setIsFetching(false);
    }
  };

  const handleCancel = () => {
    setSearchValue("");
    setIsSearchActive(false);
    getAllTransactions();
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
        <div className="form-head dashboard-head d-flex w-100 mb-5 align-items-center justify-content-between">
            <h2 className="dashboard-title mb-0">
              Food Items
            </h2>
            <div className="button-group">
              <button
                onClick={handleAddNew}
                className="btn btn-rounded text-white"
                style={{ backgroundColor: "#3D4465" }}
              >
                Add New
              </button>
              <button
                onClick={() => setShowBulkUpload(true)}
                className="btn btn-danger btn-rounded ms-4 text-white"
              >
                Add in Bulk
              </button>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <form
                onSubmit={handleSearch}
                className="d-flex align-items-center"
              >
                <select
                  className="form-select me-2"
                  value={searchItem}
                  onChange={(e) => setSearchItem(e.target.value)}
                  style={{ width: "150px" }}
                >
                  <option value="name">Food Name</option>
                  <option value="category">Category</option>
                </select>
                <input
                  type="text"
                  className="form-control me-2"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={`Search by ${
                    searchItem === "name" ? "Food Name" : "Category"
                  }`}
                  style={{ width: "300px" }}
                />
                <button type="submit" className="btn btn-primary me-2">
                  Search
                </button>
                {isSearchActive && (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                )}
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <div
                  id="example7_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <Table
                    data={data}
                    columns={[
                      { header: "S/N", accessorFn: (_, index) => index + 1 },
                      { header: "FOOD NAME", accessorKey: "food_type" },
                      {
                        header: "CATEGORY",
                        accessorFn: (row) =>
                          row.category && row.category[0]
                            ? row.category[0].category
                            : "N/A",
                      },
                      {
                        header: "AVAILABILITY",
                        cell: ({ row }) => (
                          <div className="custom-switch">
                            <Toggle
                              className="custom-switch-input"
                              id={`availabilitySwitch-${row.original.id}`}
                              defaultChecked={row.original.is_available === 1}
                              onChange={() =>
                                handleAvailabilityToggle(
                                  row.original.id,
                                  row.original.is_available
                                )
                              }
                            />
                            <label
                              className="custom-switch-label"
                              htmlFor={`availabilitySwitch-${row.original.id}`}
                            >
                              <span className="custom-switch-inner"></span>
                              <span className="custom-switch-switch"></span>
                            </label>
                          </div>
                        ),
                      },
                      { header: "PRICE", accessorKey: "price" },
                      {
                        header: "ACTION",
                        cell: ({ row }) => (
                          <div className="action-buttons d-flex">
                            <button
                              className="btn btn-icon btn-primary light me-2"
                              onClick={() =>
                                handleUpdateFoodMenu(row.original.id)
                              }
                            >
                              {SVGICON.Edit}
                            </button>
                            <button
                              className="btn btn-icon btn-danger light"
                              onClick={() =>
                                handleDeleteFoodMenu(row.original.id)
                              }
                            >
                              {SVGICON.Delete}
                            </button>
                          </div>
                        ),
                      },
                    ]}
                    loading={isFetching}
                  />
                  <Pagination
                    totalNumberOfPages={totalNumberOfPages}
                    onPageClick={handlePageClick}
                    activePage={currentPage}
                    showSizePicker={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrintReceiptModal
        show={printModalVisible}
        onHide={() => setPrintModalVisible(false)}
        // receipt={selectedReceipt}
      />
      <FoodMenuForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        foodItem={selectedFoodItem}
        onSave={handleSaveFoodMenu}
        mode={formMode}
        onEditSuccess={() => getAllTransactions()}
      />
    {showBulkUpload && (
  <div className="modal fade show" style={{display: 'block'}} tabIndex="-1">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Bulk Food Menu Upload</h5>
          <button type="button" className="btn-close" onClick={() => setShowBulkUpload(false)}></button>
        </div>
        <div className="modal-body">
          <BulkFoodMenuUpload />
        </div>
        <div className="modal-footer">
          <button 
            type="button" 
            className="btn btn-secondary" 
            onClick={() => setShowBulkUpload(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}
    </>
  );
};

export default FrontFoodItems;