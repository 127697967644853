import { apiSlice } from "../api/apiSlice";

export const ordersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDineInOrders: builder.query({
      query: (query) => ({
        url: "/orders",
        method: "GET",
        params: query,
      }),
    }),
    getOnlineOrders: builder.query({
      query: (query) => ({
        url: "/online_orders",
        method: "GET",
        params: query,
      }),
    }),
    updateProcessingOrders: builder.mutation({
      query: ({ id, data }) => ({
        url: `/orders/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    viewProcessingOrders: builder.query({
      query: ({ id, query }) => ({
        url: `/orders/${id}`,
        method: "GET",
        params: query,
      }),
    }),
  }),
});

export const {
  useGetDineInOrdersQuery,
  useGetOnlineOrdersQuery,
  useUpdateProcessingOrdersMutation,
  useViewProcessingOrdersQuery,
  endpoints,
} = ordersApi;
