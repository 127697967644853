const validatePassword = (password) => {
    const errors = [];
  
    if (!password.trim()) {
      errors.push('Password is required');
    } else {
      // Check for minimum length (8 to 15 characters)
      if (password.length < 8) {
        errors.push('Password must be at least 8 characters');
      }
      
      // Check for at least one lowercase letter
      if (!/[a-z]/.test(password)) {
        errors.push('Password must contain at least one lowercase letter');
      }
      
      // Check for at least one uppercase letter
      if (!/[A-Z]/.test(password)) {
        errors.push('Password must contain at least one uppercase letter');
      }
      
      // Check for at least one digit
      if (!/\d/.test(password)) {
        errors.push('Password must contain at least one number');
      }
      
      // Check for at least one special character
      if (!/[^a-zA-Z0-9]/.test(password)) {
        errors.push('Password must contain at least one special character');
      }
      
      // Check for spaces (should not contain spaces)
      if (/\s/.test(password)) {
        errors.push('Password cannot contain spaces');
      }
    }
  
    return errors;
  };
  
  const validateEmail = (email) => {
    const errors = [];
  
    if (!email.trim()) {
      errors.push('Email is required');
    } else {
      // Check for "@" symbol
      if (!email.includes('@')) {
        errors.push('Email must contain an "@" symbol');
      }
      
      // Check for domain
      if (!/\.[a-zA-Z]{2,}$/.test(email)) {
        errors.push('Email must contain a valid domain');
      }
  
      // Check for spaces
      if (/\s/.test(email)) {
        errors.push('Email cannot contain spaces');
      }
  
      // Additional criteria can be added as needed
    }
  
    return errors;
  };
  
  export const validateForm = (data) => {
    const errors = {};
  
    if (!data.full_name.trim()) errors.full_name = 'Full name is required';
    if (!data.business_name.trim()) errors.business_name = 'Business name is required';
  
    const emailErrors = validateEmail(data.email);
    if (emailErrors.length > 0) {
      errors.email = emailErrors.join(', ');
    }
  
    if (!data.country) errors.country = 'Country is required';
    if (!data.state.trim()) errors.state = 'State is required';
    if (!data.street.trim()) errors.street = 'Street is required';
  
    if (!data.phone_number.trim()) {
      errors.phone_number = 'Phone number is required';
    } else if (data.phone_number.length < 10) {
      errors.phone_number = 'Phone number must be at least 10 digits';
    }
  
    // Validate password with specific criteria
    const passwordErrors = validatePassword(data.password);
    if (passwordErrors.length > 0) {
      errors.password = passwordErrors.join(', ');
    }
  
    if (!data.password_confirmation.trim()) {
      errors.password_confirmation = 'Password confirmation is required';
    } else if (data.password !== data.password_confirmation) {
      errors.password_confirmation = 'Passwords do not match';
    }
  
    return errors;
  };