import React, { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaShareAlt } from "react-icons/fa";
import moment from "moment";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ExportTransactionsDropdown from "./exportFilter/ExportTransactionsDropdown";
import { endpoints } from "../../admin/redux/ConfirmedPayment/confirmedPaymentApi";
import { useDispatch } from "react-redux";

const exportToExcel = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  saveAs(dataBlob, fileName);
};

const ExportTransactions = ({ orderType, selectedFilters, setSelectedFilters }) => {
  // const [transactionRange, setTransactionRange] = useState("daily");
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setShow(false);
    setError(null);
  };
  const handleShow = () => setShow(true);

  const calculateDateRangeText = () => {
    if (!fromDate || !toDate) return '';
    const duration = moment.duration(moment(toDate).diff(moment(fromDate)));
    const days = duration.asDays();
    const months = duration.asMonths();
    const years = duration.asYears();
    
    if (years >= 1) {
      return `${Math.round(years)} year(s)`;
    } else if (months >= 1) {
      return `${Math.round(months)} month(s)`;
    } else {
      return `${Math.round(days)} day(s)`;
    }
  };

  const handleExport = async () => {
    setError(null);
  
    if (!fromDate || !toDate) {
      setError("Please select a valid date range.");
      return;
    }
  
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userId = userData.user_id;
  
    let transData = {
      "start_date": fromDate.toISOString(),
      "end_date": toDate.toISOString(),
    };
  
    const areFiltersSelected = Object.values(selectedFilters).some((value) => value);
    if (!areFiltersSelected) {
      setError("Please select at least one filter for export.");
      return;
    }
  
    try {
      const response = await dispatch(
        endpoints.exportOrders.initiate({ id: userId, query: transData })
      ).unwrap();
      
      console.log("API Response Data:", response.data);
      
  
      if (!response || typeof response !== 'object') {
        throw new Error("Invalid response received from the server");
      }
  
      if (response.status !== 'Success') {
        throw new Error(response.message || "Error exporting transactions");
      } 
  
      if (!Array.isArray(response.data)) {
        throw new Error("Invalid data format received from the server");
      }
  
      if (response.data.length === 0) {
        setError("No transactions found for the selected period");
        return;
      }
  
      const filteredData = response.data.filter((item) => {
        const itemDate = moment(item.created_at);
        return itemDate.isBetween(fromDate, toDate, 'day', '[]');
      });

      console.log("Filtered Data Based on Date:", filteredData);
  
      if (filteredData.length === 0) {
        setError("No transactions found for the selected period");
        return;
      }
  
      const fieldsToInclude = Object.keys(selectedFilters).filter(
        (key) => selectedFilters[key]
      );

      console.log("fieldsToInclude", fieldsToInclude);
      
  
      const dataForExport = filteredData.map((item) => {
        const filteredItem = {};
        fieldsToInclude.forEach((field) => {
          if (item[field] !== undefined) {
            filteredItem[field] = item[field];
          }
        });
        console.log("Filtered Item:", filteredItem);
        return filteredItem;
      });
      
      console.log("Data for Export:", dataForExport);

      const fileName = `${orderType}_Transactions_${moment().format('YYYY-MM-DD')}.xlsx`;
      exportToExcel(dataForExport, fileName);
      handleClose();
    } catch (error) {
      console.error("Export error:", error);
      setError(error.message || "Error exporting data. Please try again.");
    }
  };
  

  return (
    <>
      <ExportTransactionsDropdown
        handleShow={handleShow}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FaShareAlt className="me-2" />
            EXPORT TRANSACTIONS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="pr/-5">From:</Form.Label>
              <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                selectsStart
                startDate={fromDate}  
                endDate={toDate}
                className="form-control"
              />
              <br/>
              <br/> 
              <Form.Label className="mt-2">To:</Form.Label>
              <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                selectsEnd
                startDate={fromDate}
                endDate={toDate}
                minDate={fromDate}
                className="form-control"
              />
            </Form.Group>
            <div>
              <strong>Date Range:</strong> {calculateDateRangeText()}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleExport}>
            Export
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportTransactions;