import { apiSlice } from "../api/apiSlice";

export const confirmedPaymentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOnlineDineInInvoiceList: builder.query({
      query: (query) => ({
        url: "/payments",
        method: "GET",
        params: query,
      }),
    }),
    printTransaction: builder.query({
      query: (id) => ({
        url: `/payments/${id}`,
        method: "GET",
      }),
    }),
    exportTransaction: builder.query({
      query: ({ id, query }) => ({
        url: `/restaurant_exported_orders/${id}`,
        method: "GET",
        params: query,
        headers: {
          'Authorization': localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
      }),
    }),
    exportOrders: builder.query({
      query: ({ id, query }) => ({
        url: `/restaurant_exported_orders/${id}`,
        method: "GET",
        params: query,
        // headers: {
        //   'Authorization': localStorage.getItem("token"),
        //   'Content-Type': 'application/json',
        // },
      }),
    }),
    getOwnerQrMidTransaction: builder.query({
      query: () => ({
        url: `/get_owner_qrmid`,
        method: "GET",
      }),
    }),
    getPaymentOnly: builder.query({
      query: ({ id, query }) => ({
        url: `https://qrapi.paysaddle.com/res_payments/${id}`,
        method: "GET",
        params: query,
      }),
    }),
  }),
});

export const { useGetOnlineDineInInvoiceListQuery, endpoints } =
  confirmedPaymentApi;
