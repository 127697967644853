import React, { forwardRef } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import styled from "styled-components";

import Spinner from "./icons/spinner.svg?react";

const CustomSelect = forwardRef(
  (
    {
      options,
      defaultValue,
      $dataId,
      label,
      error,
      required,
      $marginbottom,
      height,
      border,
      placeholder,
      loading,
      className,
      ...rest
    },
    ref
  ) => {
    const customStyles = {
      control: (provided) => ({
        ...provided,
        border: `1px solid ${error ? "#FF6347" : border ?? "#343a40"}`,
        borderRadius: "0.625rem",
        width: "100%",
        minWidth: "10rem",
        outline: "none",
        padding: "0.25rem 0.75rem",
        fontSize: "16px",
        backgroundColor: "transparent",
        cursor: "pointer",
        boxShadow: "none",
        display: "flex",
        justifyContent: "space-between",
        gap: "0.5rem",
        height: height ?? "3.5rem",
        "&:hover": {
          borderColor: "#007bff"
        }
      }),

      valueContainer: (provided) => ({
        ...provided,
        padding: "0",
        height: "100%",
        overflowY: "auto"
      }),

      input: (provided) => ({
        ...provided,
        padding: "0"
      }),

      singleValue: (provided) => ({
        ...provided,
        color: "var(--black-shade-01)"
      }),

      indicatorsContainer: (provided) => ({
        ...provided,
        padding: "0",
        "& > div": {
          padding: "0"
        }
      }),
      menu: (provided) => ({
        ...provided,
        overflow: "hidden",
        borderRadius: "0.875rem",
        border: "1px 1px 1px 1px solid #343a40"
      }),
      menuList: (provided) => ({
        ...provided,
        padding: "0rem",
        border: "none",
        outline: "none",
        boxShadow: "none",
        "& :first-child": {
          borderRadius: "0.625rem 0.625rem 0 0"
        },
        "& :last-child": {
          borderRadius: "0 0 0.625rem 0.625rem"
        }
      }),
      menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
      option: (provided, state) => ({
        ...provided,
        border: "1px solid var(--black-shade-05)",
        cursor: "pointer",
        height: "2.5rem",
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: state.isSelected ? "#007bff" : "inherit",
        "&:hover": {
          backgroundColor: "var(--green-shade-05)",
          color: "var(--black-shade-01)"
        }
      })
    };

    return (
      <Container required={required} $marginbottom={$marginbottom ?? "2rem"} ref={ref} className={className} {...rest}>
        <div className='label'>{label && <label>{label}</label>}</div>
        <div className='inputAndSpinnerDiv'>
          {loading && <Spinner className='spinner' />}
          <Select
            data-id={$dataId}
            options={options}
            menuPosition='fixed'
            styles={customStyles}
            defaultValue={Array.isArray(options) && options.find((option) => option.value === defaultValue)}
            menuShouldScrollIntoView
            menuPlacement='auto'
            menuPortalTarget={document.body}
            placeholder={placeholder}
            components={{
              IndicatorSeparator: () => null
            }}
            {...rest}
          />
        </div>

        {error && <span className='error'>{error}</span>}
      </Container>
    );
  }
);

CustomSelect.displayName = "CustomSelect";

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  $dataId: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  $marginbottom: PropTypes.string,
  border: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  height: PropTypes.string
};

export default CustomSelect;

const Container = styled.div`
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: ${({ $marginbottom }) => $marginbottom};

  [aria-disabled="true"] {
    cursor: not-allowed;
    pointer-events: none;
    background-color: var(--disabledInput, #f5f5f5);
    border: 1px solid var(--disabledInput, #f5f5f5);
  }

  label {
    font-size: 16px;
    color: var(--black-shade-01, #000);
    text-align: left;
    display: flex;
    align-items: center;
    line-height: 1;
    gap: 0.125rem;
    margin-bottom: 0.5rem;
    position: relative;

    ${({ required }) =>
      required
        ? `
      &::after {
        content: '*';
        color: red;
      }
      `
        : ""}
  }

  .inputAndSpinnerDiv {
    position: relative;

    .spinner {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translateY(-50%);

      g {
        stroke: #007bff;
      }
    }
  }

  .error {
    font-size: 12px;
    color: var(--red-shade-01, #eb5757);
    position: absolute;
    left: 0;
    text-align: left;
    padding: 0.25rem 0px 0px 0px;
    margin: 0px;
    line-height: 1;
  }
`;
