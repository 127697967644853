import React, { useState, useEffect } from 'react';
import { useAddCategoryMutation, useAddSubCategoryMutation, useDeleteCategoryMutation, useDeleteSubCategoryMutation, useFetchCategoryQuery, useFetchSubCategoryQuery, useFetchSuperCategoryQuery, useUpdateCategoryMutation, useUpdateSubCategoryMutation } from '../../admin/redux/Setup/setupApi';
import { Link } from 'react-router-dom';
import { SVGICON } from '../constant/theme';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
 
const CategoryGrid = () => {
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [newSubCategory, setNewSubCategory] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [newCategoryImage, setNewCategoryImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);    
    const [selectedOption, setSelectedOption] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const [imageBase64, setImageBase64] = useState(''); // Store base64 string
    const [imageExtension, setImageExtension] = useState(''); // Store image file extension
    // const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [action, setAction] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [subcategoryAction, setSubcategoryAction] = useState(null);
    const [extraInstructions, setExtraInstructions] = useState("");

    const { data: response, isFetching, refetch: refetchCategory } = useFetchCategoryQuery();
    const { data: subcategoryResponse, isFetching:subCategoryLoading, refetch: refetchSubCategory  } = useFetchSubCategoryQuery(selectedCategoryId, {
        skip: !selectedCategoryId, // Prevent automatic fetching until category is selected
    });
    const {data: superCategory} = useFetchSuperCategoryQuery();
console.log(newCategoryImage, loading);

    useEffect(() => {
        if (response && response.data) {
            setCategories(response.data);
        }
    }, [response]);

    useEffect(() => {
        if (subcategoryResponse) {
            setSubCategories(subcategoryResponse.data || []);
        }
    }, [subcategoryResponse]);

    // Close modal and reset all modal-related states
    const closeModal = () => {
        setModal(false);
        setModalData(null);
        setAction(null);
        setSelectedSubCategory(null);
        setSubcategoryAction(null);
        setNewCategory('');
        setNewCategoryImage(null);
        setImagePreview(null);
        setExtraInstructions('');
        setSelectedOption('');
        setImageBase64('');
        setImageExtension('');
    };

    const OpenModal = (data, action, categoryId = null) => {
        setModal(true);
        setModalData(data);
        setAction(action);

        if (action === "view" && categoryId) {   
            setSubCategories(null)         
            setSelectedCategoryId(categoryId); // Set category ID only on "view" action
            // refetchSubCategory(); // Manually trigger the subcategory fetch
        }
    };

    // Convert the image file to base64
    const handleImageAdd = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewCategoryImage(file);
            setImagePreview(URL.createObjectURL(file));

            // Extract the file extension
            const extension = file.name.split('.').pop();
            setImageExtension(extension);

            // Convert the image to a base64 string
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = "data:application/octet-stream;base64," + reader.result.split(',')[1]
                
                setImageBase64(base64); // Strip out the base64 prefix
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [addCategory] = useAddCategoryMutation();
    const [updateCategory] = useUpdateCategoryMutation();
    const [deleteCategory] = useDeleteCategoryMutation();
    const [addSubCategory] = useAddSubCategoryMutation();
    const [updateSubCategory] = useUpdateSubCategoryMutation();
    const [deleteSubCategory] = useDeleteSubCategoryMutation();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            console.log(URL.createObjectURL(file));
            setModalData({...modalData, display: URL.createObjectURL(file), display_name: file.name}) // Store the selected image
        }
    };

    const handleAddSubCategory = async (e) =>{
        e.preventDefault();
        const data = {
            category_id: `${selectedCategoryId}`,
            sub_category: newSubCategory,
            event_id: null

        }
        setLoading(true)
        try {
            await addSubCategory(data);
            refetchCategory();
            refetchSubCategory()
            closeModal(); // Close modal and reset state after successful addition
        } catch (error) {
            console.error("Error adding category:", error);
            toast(error.message)
        } finally {
            setLoading(false); // Hide loading state
        }
    }

    const handleAddCategory = async (e) => {
        e.preventDefault();
        if (!newCategory || !selectedOption) {
            alert("Please fill out all required fields.");
            return;
        }

        const data = {
            category: newCategory,
            extra_instructions: extraInstructions,
            super_category_id: selectedOption,
            image: imageBase64, // Add the base64 string
            extension: imageExtension, // Add the image extension
            event_id: null
        };

        setLoading(true); // Show loading state
        try {
            await addCategory(data);
            refetchCategory();
            closeModal(); // Close modal and reset state after successful addition
        } catch (error) {
            console.error("Error adding category:", error);
        } finally {
            setLoading(false); // Hide loading state
        }
    };

    // const handleEditCategory = async (e) => {
    //     e.preventDefault();
    //     if (!modalData.category) {
    //         alert("Category name cannot be empty.");
    //         return;
    //     }

    //     setLoading(true);
    //     try {
    //         await updateCategory({ categoryId: modalData.id, body: modalData });
    //         refetchCategory();
    //         closeModal();
    //     } catch (error) {
    //         console.error("Error updating category:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleDeleteCategory = async () => {
        setLoading(true);
        try {
            await deleteCategory(modalData.id);
            refetchCategory();
            refetchSubCategory();
            closeModal();
        } catch (error) {
            console.error("Error deleting category:", error);
        } finally {
            setLoading(false);
        }
    };

    // const handleEditSubCategory = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     try {
    //         await updateSubCategory({ subCategoryId: selectedSubCategory.id, body: selectedSubCategory });
    //         refetchSubCategory();
    //         closeModal();
    //     } catch (error) {
    //         console.error("Error updating subcategory:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // const handleDeleteSubCategory = async () => {
    //     setLoading(true);
    //     try {
    //         await deleteSubCategory(selectedSubCategory.id);
    //         refetchSubCategory();
    //         closeModal();
    //     } catch (error) {
    //         console.error("Error deleting subcategory:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center my-4">
                <h2 className="mb-0">Food Category</h2>
                <button className="btn btn-warning" onClick={() => OpenModal(null, "add")}>
                    Add Category
                </button>
            </div>
            {isFetching ? <p>Loading categories...</p> : (
                <div className="table-responsive">
                    <table className="table mb-4">
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Image</th>
                                <th className="">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map((data, ind) => (
                                <tr key={ind}>
                                    <td>{data.category}</td>
                                    <td>
                                        <img src={data.display} alt={data.name} style={{ width: '35px', height: '35px' }} />
                                    </td>
                                    <td>
                                        <div className="action-buttons d-flex">
                                            <Link to={"#"} className="btn btn-success light me-2"
                                                data-bs-toggle="modal"
                                                onClick={() => OpenModal(data, "view", data.id)}>
                                                {SVGICON.Eyes}
                                            </Link>
                                            <Link to={"#"} className="btn btn-primary light me-2"
                                                data-bs-toggle="modal"
                                                onClick={() => OpenModal(data, "edit")}>
                                                {SVGICON.Edit}
                                            </Link>
                                            <Link to={"#"} className="btn btn-danger light"
                                                data-bs-toggle="modal"
                                                onClick={() => OpenModal(data, "delete")}>
                                                {SVGICON.Delete}
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Modal Code */}
            <Modal className="modal order-info-box" id="OrderInfoBox" show={modal} onHide={closeModal}>
                <div className="modal-header">
                    <h5 className="user-name">{modalData ? modalData?.category : "Add New Category"}</h5>
                </div>

                    {action === "view" && (
                    <div className="modal-body">
                        <button className="btn btn-warning mb-3 justify-self-end" onClick={() => OpenModal(null, "add sub")}>
                            Add Sub Category
                        </button>
                        {!subCategoryLoading ? (
    subCategories?.length > 0 ? (
        <ul className="order-list-wrapper">
            <li className="list-header">
                Subcategory <span>Action</span>
            </li>
            {subCategories.map((subCategory) => (
                <li key={subCategory.id} className="list-item me-2 mb-2">
                    {subCategory.sub_category}
                    <span>
                        <div className="action-buttons d-flex justify-content-end">
                            <Link
                                to={"#"}
                                className="btn btn-primary light me-2"
                                onClick={() => {
                                    setSelectedSubCategory(subCategory);
                                    setSubcategoryAction("edit");
                                }}
                            >
                                {SVGICON.Edit}
                            </Link>
                            <Link
                                to={"#"}
                                className="btn btn-danger light"
                                onClick={() => {
                                    setSelectedSubCategory(subCategory);
                                    setSubcategoryAction("delete");
                                }}
                            >
                                {SVGICON.Delete}
                            </Link>
                        </div>
                    </span>
                </li>
            ))}
        </ul>
    ) : (
        <h5>No Subcategories Available</h5>
    )
) : (
    <h5>...Loading</h5>
)}
</div>
                )}
                     {/* Add Sub Category Form */}
                     {action === "add sub" && (
                        <div className='modal-body'>
                        <form onSubmit={handleAddSubCategory}>
                            
                            <div className="form-group">
                                <label>Sub Category Name</label>
                                <input
                                    type="text"
                                    value={newSubCategory}
                                    onChange={(e) => setNewSubCategory(e.target.value)}
                                    className="form-control"
                                    placeholder="Enter Sub category"
                                />
                            </div>
                            <button type="submit" className="btn btn-warning">
                                Add Sub Category
                            </button>
                        </form>
                        </div>

                    )}
                {/* Edit Subcategory */}
                {subcategoryAction === "edit" && selectedSubCategory && (
                    <div className="modal-body">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            // Assuming `selectedSubCategory` holds the updated fields
                            updateSubCategory({ subCategoryId: selectedSubCategory.id, body: selectedSubCategory });
                            setSubcategoryAction(null);
                            setModal(false); // Close modal after submission
                            }}>
                            <div className="form-group">
                                <label htmlFor="subCategoryName">Subcategory Name</label>
                                <input
                                    type="text"
                                    id="subCategoryName"
                                    className="form-control"
                                    value={selectedSubCategory.sub_category}
                                    onChange={(e) => setSelectedSubCategory({ ...selectedSubCategory, sub_category: e.target.value })}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Save Changes</button>
                        </form>
                    </div>
                )}

                {/* Delete subcategory */}
                {subcategoryAction === "delete" && selectedSubCategory && (
                    <div className="modal-body">
                        <h5>Are you sure you want to delete this subcategory?</h5>
                        <button onClick={() => {
                            deleteSubCategory(selectedSubCategory.id);
                            setSubcategoryAction(null);
                            setModal(false);
                            refetchCategory(); refetchSubCategory();
                        }} className="btn btn-danger mt-3">
                            Yes, Delete
                        </button>
                    </div>
                )}

                    {/* Add Category Form */}
                    {action === "add" && (
                        <div className='modal-body'>
                            <form onSubmit={handleAddCategory}>
                                <div className="form-group">
                                    <label>Super Category</label>
                                    <select className="form-control" value={selectedOption} onChange={handleOptionChange}>
                                        <option value="">Select Super Category</option>
                                        {superCategory?.data.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Category Name</label>
                                    <input
                                        type="text"
                                        value={newCategory}
                                        onChange={(e) => setNewCategory(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter new category"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Upload Image</label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageAdd}
                                        className="form-control"
                                    />
                                    {imagePreview && (
                                        <img src={imagePreview} alt="Preview" style={{ width: "100px", height: "100px" }} />
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Extra Instructions</label>
                                    <textarea
                                        value={extraInstructions}
                                        onChange={(e) => setExtraInstructions(e.target.value)}
                                        className="form-control"
                                        placeholder="Add extra instructions here"
                                    />
                                </div>
                                <button type="submit" className="btn btn-warning">
                                    Add Category
                                </button>
                            </form>
                        </div>
                    )}

                    {/* Edit Category Form */}
                    {action === "edit" && modalData && (
                        <div className="modal-body">
                            <form onSubmit={(e) => { 
                                e.preventDefault(); 
                                updateCategory({ categoryId: modalData.id, body: {...modalData, display: ""} });
                            }}>
                                {/* Category Name */}
                                <div className="form-group">
                                    <label htmlFor="categoryName" className="mb-2"><strong>Category Name</strong></label>
                                    <input
                                        type="text"
                                        id="categoryName"
                                        className="form-control"
                                        value={modalData?.category || ''}
                                        onChange={(e) => setModalData({ ...modalData, category: e.target.value })}
                                    />
                                </div>

                                {/* Extra Instructions */}
                                <div className="form-group">
                                    <label htmlFor="extraInstructions" className="mb-2"><strong>Extra Instructions</strong></label>
                                    <textarea
                                        id="extraInstructions"
                                        className="form-control"
                                        style={{
                                            height: "200px"
                                        }}
                                        placeholder={modalData?.extra_instructions}
                                        value={modalData?.extra_instructions || ''}
                                        onChange={(e) => setModalData({ ...modalData, extra_instructions: e.target.value })}
                                    />
                                </div>

                                {/* Image Preview */}
                                <div className="form-group">
                                    <label className="mb-2"><strong>Image</strong></label>
                                    <div className="image-upload-preview mb-3">
                                        {modalData?.display ? (
                                            <img
                                                src={modalData.display}
                                                alt="Category"
                                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                            />
                                        ) : (
                                            <div>No image</div>
                                        )}
                                    </div>
                                    <div>

                                        <input
                                            type="file"
                                            className="form-control"
                                            accept="image/*" // Accepts only image files
                                            onChange={handleImageChange} // Handle file selection
                                        />
                                    </div>
                                </div>

                                {/* Save Changes and Clear Button */}
                                <div className="d-flex justify-content-between">
                                    <button type="submit" className="btn btn-primary">Save Changes</button>
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        onClick={() => {
                                            // Reset modalData to the original state (or fetch original data if necessary)
                                            setModalData(response.data.find(cat => cat.id === modalData.id));
                                        }}
                                    >
                                        Clear Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}

                    {/* Delete Category */}
                    {action === "delete" && (
                        <div>
                            <p>Are you sure you want to delete this category?</p>
                            <button className="btn btn-danger" onClick={handleDeleteCategory}>
                                Yes, Delete
                            </button>
                        </div>
                    )}
            </Modal>
        </div>
    );
};

export default CategoryGrid;
