/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { IMAGES, SVGICON } from "../constant/theme";
import { endpoints } from "../../admin/redux/ConfirmedPayment/confirmedPaymentApi";
import Pagination from "../components/Pagination/Pagination";
import Table from "../components/Table/Table";
import ExportTransactions from "../components/ExportTransactions";
import { createSearchParams, useNavigate } from "react-router-dom";
import useGetUrlQuery from "../hooks/useGetUrlQuery";
import { useDispatch } from "react-redux";
import PrintReceiptModal from "../components/PrintReceiptModal";
import { formatDateTime } from "../../utils/formatDateTime";

const FrontTransaction = () => {
  const [detailModal, setDetailModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const urlSearchParams = new URLSearchParams(window.location.search);

  const currentPage = Number(urlSearchParams.get("page")) || 1;
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
  const navigate = useNavigate();
  const urlQuery = useGetUrlQuery();
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    gen_order_id: { label: "Order ID", dbColumn: "gen_order_id", isChecked: false },
    payment_type: { label: "Payment Type", dbColumn: "payment_type", isChecked: false },
    transaction_id: { label: "Transaction ID", dbColumn: "transaction_id", isChecked: false },
    payer_name: { label: "Payer Name", dbColumn: "payer_name", isChecked: false },
    order_type: { label: "Order Type", dbColumn: "order_type", isChecked: false },
    total_bill: { label: "Total Bill", dbColumn: "total_bill", isChecked: false },
    created_at: { label: "Transaction Date", dbColumn: "created_at", isChecked: false },
  });
  

  useEffect(() => {
    getAllTransactions();
  }, [currentPage, dispatch]);

  const getAllTransactions = async () => {
    setIsFetching(true);
    try {
      const response = await dispatch(
        endpoints.getOnlineDineInInvoiceList.initiate({ page: currentPage })
      ).unwrap();
      console.log(response);
      setData(response?.data?.data);
      setTotalNumberOfPages(response?.data?.last_page);
      setIsFetching(false);
    } catch (error) {
      setData([]);
      setTotalNumberOfPages(0);
      setIsFetching(false);
    }
  };

  const handlePrintReceipt = async (id) => {
    try {
      const response = await dispatch(
        endpoints.printTransaction.initiate(id)
      ).unwrap();
      console.log(response);
      setSelectedReceipt(response.data);
      setPrintModalVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = (page) => {
    const query = { ...urlQuery, page: page };
    navigate(`?${createSearchParams(query).toString()}`);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-md-flex d-block mb-5 align-items-start">
            <h2 className="dashboard-title me-auto">
              Dine-In / Online Payments
            </h2>
            <ExportTransactions selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters}/>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <div
                  id="example6_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <Table
                    data={data}
                    columns={[
                      { header: "S/N", accessorFn: (_, index) => index + 1 },
                      { header: "ORDER ID", accessorKey: "order_id" },
                      { header: "PAYMENT TYPE", accessorKey: "payment_type" },
                      {
                        header: "TRANSACTION ID",
                        accessorKey: "transaction_id",
                      },
                      { header: "PAYER'S NAME", accessorKey: "payers_name" },
                      {
                        header: "ORDER TYPE",
                        cell: ({ row }) =>
                          row.original.is_online ? "Online Order" : "Dine In",
                      },
                      {
                        header: "TRANSACTION DATE",
                        accessorFn: (row) => formatDateTime(row.created_at),
                      },
                      { header: "TOTAL BILL", accessorKey: "amount" },
                      {
                        header: "ACTION",
                        cell: ({ row }) => {
                          // const id = row.original.id;
                          return (
                            <div className="action-buttons d-flex justify-content-end">
                              <Link
                                to="#"
                                className="btn btn-success light me-2"
                                onClick={() => {
                                  console.log(
                                    "Print icon clicked for id:",
                                    row.original.id
                                  );
                                  handlePrintReceipt(row.original.id);
                                }}
                              >
                                {SVGICON.Print}
                              </Link>
                            </div>
                          );
                        },
                      },
                    ]}
                    loading={isFetching}
                  />
                  <Pagination
                    totalNumberOfPages={totalNumberOfPages}
                    onPageClick={handlePageClick}
                    activePage={currentPage}
                    showSizePicker={false}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Modal  */}
          <Modal
            className="modal order-info-box"
            id="OrderInfoBox"
            show={detailModal}
            onHide={setDetailModal}
          >
            <div className="modal-header">
              <div>
                <h5 className="user-name">John Doe</h5>
                <span className="date">B00123 | 15 Jun, 11:35 am</span>
              </div>
            </div>
            <div className="modal-body">
              <ul className="order-list-wrapper">
                <li className="list-header">
                  <span className="sn">Q.</span>ITEMS<span>PRICE</span>
                </li>
                <li>
                  <span className="sn">1.</span>Onion Sandwich
                  <span>$12.00</span>
                </li>
                <li>
                  <span className="sn">1.</span>Onion Sandwich
                  <span>$12.00</span>
                </li>
                <li>
                  <span className="sn">1.</span>Onion Sandwich
                  <span>$12.00</span>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <div className="w-100">
                <ul className="order-list-wrapper w-100">
                  <li>
                    Sub Total<span>$51.00</span>
                  </li>
                  <li>
                    Service fee<span>$3.00</span>
                  </li>
                  <li className="list-footer">
                    Total Paid in <strong>CASH</strong>
                    <span>$54.00</span>
                  </li>
                </ul>
                <div className="list-profie mt-3">
                  <img src={IMAGES.Avatar1} className="me-3" alt="" />
                  <div>
                    <h4 className="name">John Anderson</h4>
                    <span className="sub-info">Delivery partner assigned</span>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal  end */}
        </div>
      </div>
      <PrintReceiptModal
        show={printModalVisible}
        onHide={() => setPrintModalVisible(false)}
        receipt={selectedReceipt}
      />
    </>
  );
};

export default FrontTransaction;
