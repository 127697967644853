import { configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "./api/apiSlice";
import regReducer from "./Auth/regSlice"
import authReducer from "./api/authSlice"
import { authApi } from "./Auth/authApi";

// Annotate the store variable with the RootState type
const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    userReg: regReducer,
    [authApi.reducerPath]: authApi.reducer,
    // Add other reducers as needed
  },

  // add middleware to the store
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware, authApi.middleware),

  // Enable Redux DevTools Extension
  devTools: process.env.NODE_ENV === "development" ? true : false
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;

export default store;
