import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../redux/Auth/authApi";
import { encryptPassword } from "../../../utils/encryption";
import { toast } from "react-toastify";

const PasswordResetForm = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [resetPassword, { isLoading }] = useResetPasswordMutation();
    const [, setMessage] = useState("");

    const queryString = useLocation().search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");

    const onSubmit = async (e) => {
        e.preventDefault();

        if (password !== passwordConfirmation) {
            toast.error("Passwords do not match!");
            return;
        }

        try {
            const result = await resetPassword({
                email,
                password: encryptPassword(password),
                password_confirmation: encryptPassword(passwordConfirmation),
                token,
            }).unwrap();
            setMessage(result.message);
            navigate("/login", { replace: true });
            toast.done(result.message)
        } catch (err) {
            console.error(err);
            toast.error("Password reset failed");
        }
    };

    return (
        <div className="authincation h-100 p-meddle">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <h4 className="text-center mb-4">Reset Password</h4>
                                        <form onSubmit={onSubmit}>
                                            <div className="form-group">
                                                <label>
                                                    <strong>Email</strong>
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                    disabled={isLoading}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    <strong>Password</strong>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                    disabled={isLoading}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    <strong>Confirm Password</strong>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    value={passwordConfirmation}
                                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                                    required
                                                    disabled={isLoading}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <input
                                                    type="submit"
                                                    value={isLoading ? "Processing..." : "RESET PASSWORD"}
                                                    className="btn btn-primary btn-block"
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </form>
                                        {/* {isSuccess && <p className="text-center mt-3">{message}</p>}
                                        {isError && <p className="text-center mt-3 text-danger">{error?.data?.message || "Failed to reset password"}</p>} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetForm;
