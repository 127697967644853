import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useGetCategoriesQuery,
  useGetSubCategoriesQuery,
} from "../../admin/redux/MenuList/menuList";
import axios from "axios";
// import { Modal } from "react-bootstrap";

const FoodMenuForm = ({
  isOpen,
  onEditSuccess,
  onClose,
  foodItem,
  onSave,
  mode,
}) => {
  // const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    food_type: "",
    category_id: "",
    sub_category_id: "",
    price: "",
    description: "",
    is_available: true,
    photo_file: null,
  });

  const { data: subCategoriesData } = useGetSubCategoriesQuery(undefined, {});

  const { data: categoriesData } = useGetCategoriesQuery(undefined, {});
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (foodItem && mode === "edit") {
      setFormData({
        food_type: foodItem.food_type || "",
        category_id: foodItem.category_id || "",
        sub_category_id: foodItem.sub_category_id || "",
        price: foodItem.price || "",
        description: foodItem.description || "",
        is_available: foodItem.is_available === 1,
        photo_file: null,
      });
      setImagePreview(foodItem.photo_url);
    } else {
      setFormData({
        food_type: "",
        category_id: "",
        sub_category_id: "",
        price: "",
        description: "",
        is_available: true,
        photo_file: null,
      });
      setImagePreview(null);
    }
  }, [foodItem, mode]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:
        type === "checkbox"
          ? checked
          : type === "number"
          ? Number(value)
          : value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, photo_file: file }));

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    const requiredFields = ["food_type", "category_id", "price"];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      missingFields.forEach((field) =>
        toast.error(`The ${field.replace("_", " ")} field is required.`)
      );
      return;
    }

    try {
      let data;
      if (mode === "edit") {
        // For edit, send JSON data
        data = {
          food_type: formData.food_type,
          category_id: formData.category_id,
          sub_category_id: formData.sub_category_id,
          price: formData.price,
          description: formData.description,
          is_available: formData.is_available ? 1 : 0,
        };
      } else {
        // For create, use FormData
        data = new FormData();
        Object.keys(formData).forEach((key) => {
          if (key === "is_available") {
            data.append(key, formData[key] ? "1" : "0");
          } else if (key === "price") {
            data.append(key, formData[key].toString());
          } else if (key !== "photo_file") {
            data.append(key, formData[key]);
          }
        });
        if (formData.photo_file) {
          data.append("photo_file", formData.photo_file);
        }
      }

      // Log the data being sent
      console.log(
        "Data being sent:",
        mode === "edit" ? data : Object.fromEntries(data)
      );
      const token = localStorage.getItem("token");
      if (mode === "edit") {
        await axios.put(
          `${process.env.REACT_APP_BASE_URL}/food_menus/${foodItem.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.replace(/['"]+/g, "")}`,
            },
          }
        );
      } else {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/food_menus`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.replace(/['"]+/g, "")}`,
          },
        });
      }

      onEditSuccess();
      onClose();
      toast.success(
        `Food item ${mode === "edit" ? "updated" : "created"} successfully`
      );
    } catch (error) {
      console.error("Error saving food item:", error);
      if (error.response && error.response.data && error.response.data.errors) {
        Object.values(error.response.data.errors).forEach((errorMessages) => {
          errorMessages.forEach((message) => toast.error(message));
        });
      } else {
        toast.error(
          `Error ${mode === "edit" ? "updating" : "creating"} food item: ${
            error.message || "Unknown error"
          }`
        );
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div
    className="food-menu-form"
    style={{
      position: "fixed",
      right: 0,
      top: 0,
      bottom: 0,
      width: "50%",
      backgroundColor: "white",
      padding: "20px",
      boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
      overflowY: "auto",
    }}
  >
      <h2>{mode === "edit" ? "Edit Food Menu" : "Add New Food Item"}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Photo</label>
          <div
            style={{
              width: "100px",
              height: "100px",
              border: "1px dashed #ccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Product"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            ) : (
              <span>No image</span>
            )}
          </div>
          <button
            type="button"
            onClick={() => document.getElementById("photo-upload").click()}
            className="btn btn-success"
          >
            Add Photo
          </button>
          <input
            id="photo-upload"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept="image/*"
          />
        </div>
        <div className="form-group">
          <label>Product Name*</label>
          <input
            type="text"
            className="form-control"
            name="food_type"
            value={formData.food_type}
            onChange={handleInputChange}
            placeholder="Enter product name"
            required
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Select Category*</label>
            <select
              className="form-control"
              name="category_id"
              value={formData.category_id}
              onChange={handleInputChange}
              required
            >
              <option value="">Select a category</option>
              {categoriesData?.data?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.category}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-6">
            <label>Sub Category (optional)</label>
            <select
              className="form-control"
              name="sub_category_id"
              value={formData.sub_category_id}
              onChange={handleInputChange}
            >
              <option value="">Select a subcategory</option>
              {Array.isArray(subCategoriesData?.data) &&
                subCategoriesData.data.map((subCategory) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.sub_category}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label>Price*</label>
          <input
            type="number"
            className="form-control"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
            placeholder="Enter price"
            required
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            className="form-control"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Enter description"
            required
          ></textarea>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="is_available"
            checked={formData.is_available}
            onChange={handleInputChange}
          />
          <label className="form-check-label">Available</label>
        </div>
        <div className="ml-2">
          <button type="submit" className="btn btn-primary">
            {mode === "edit" ? "Update" : "Create"} Food Item
          </button>
          <button onClick={onClose} className="btn btn-primary">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default FoodMenuForm;
