/* eslint-disable react/prop-types */
import React, { useCallback, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import Skeleton from "./Skeleton/Skeleton";

import "./Table.scss";

const Table = ({
  data,
  columns,
  onRowClick,
  loading,
  showHeader = true,
  enableRowSelection = true,
  onRowSelectionChange,
  className,
  ...props
}) => {
  const [rowSelection, setRowSelection] = useState({});

  const handleOnRowSelectionChange = useCallback(
    (valueFn) => {
      if (typeof valueFn === "function") {
        const updatedRowSelection = valueFn(rowSelection);
        setRowSelection(updatedRowSelection);

        const selectedRows = Object.keys(updatedRowSelection).reduce((acc, key) => {
          if (updatedRowSelection[key]) {
            const index = parseInt(key, 10);
            const row = data[index];
            if (row) {
              acc.push(row);
            }
          }
          return acc;
        }, []);

        onRowSelectionChange?.(selectedRows);
      }
    },
    [data, onRowSelectionChange, rowSelection]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection
    },
    enableRowSelection: enableRowSelection,
    onRowSelectionChange: handleOnRowSelectionChange,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div {...props} className={cx("tableContainer", className)}>
      <table>
        {showHeader && (
          <thead className={cx("tableHead")}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const customClassName = header.column.columnDef.meta?.className;

                  return (
                    <th className={customClassName} key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        )}

        <tbody className={cx("tableBody")}>
          {loading ? (
            <tr>
              <td colSpan={table.getHeaderGroups()[0].headers.length}>
                <Skeleton />
              </td>
            </tr>
          ) : table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id} onClick={() => (onRowClick ? onRowClick(row.original) : null)}>
                {row.getVisibleCells().map((cell) => {
                  const customClassName = cell.column.columnDef.meta?.className;

                  return (
                    <td className={customClassName} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={table.getHeaderGroups()[0].headers.length}
                style={{
                  textAlign: "center",
                  width: "100%",
                  fontSize: "1.125rem",
                  verticalAlign: "middle"
                }}
              >
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
  showHeader: PropTypes.bool,
  className: PropTypes.string,
  enableRowSelection: PropTypes.bool,
  onRowSelectionChange: PropTypes.func
};

Table.defaultProps = {
  loading: false,
  showHeader: true,
  enableRowSelection: true,
  className: "",
  data: [],
  columns: []
};

export default Table;
